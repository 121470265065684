// TODO - move zod schemas elsewhere
import { z } from 'zod';

import { MeetingHeySpinachResultSchema, MeetingHeySpinachTicketCreationResultSchema } from '@spinach-shared/schemas';

import { AvatarSharedState } from './Avatar';
import { CalendarEvent } from './Calendar';
import {
    DetailedTicket,
    IssueType,
    JiraProject,
    JiraUser,
    MappedIssueChangeLogHistoryItem,
    MinimalTicket,
    TicketStatus,
} from './Jira';
import { ScribeMeetingType, StartStop, UUID } from './Series';
import { SlackTeamType } from './Slack';
import { TicketSource } from './Tickets';
import { ISODateString } from './Time';
import { SummaryFeatureIntents } from './User';

export enum TranscriptionService {
    AssemblyAI = 'assemblyai',
    Rev = 'rev',
    Deepgram = 'deepgram',
    Gladia = 'gladia',
}

export type JiraIssueFields = {
    statuscategorychangedate: ISODateString;
    issuetype: IssueType;
    timespend: number | null;
    project: JiraProject;
    watches: {
        self: string;
        watchCount: number;
        isWatching: boolean;
    };
    lastViewed: ISODateString;
    created: ISODateString;
    priority: {
        self: string;
        iconUrl: string;
        name: string;
        id: string;
    };
    labels: string[];
    assignee: JiraUser;
    reporter: JiraUser;
    updated: ISODateString;
    status: TicketStatus;
    description: string;
    summary: string;
    creator: JiraUser;
    subtasks: any[];
};

export type JiraCreateIssueRequest = {
    fields?: Partial<
        Pick<JiraIssueFields, 'summary' | 'priority' | 'labels' | 'description'> & {
            project: Pick<JiraProject, 'id'>;
            issuetype: Pick<IssueType, 'id'>;
            assignee: { name: string };
            reporter: { name: string };
        }
    >;
};

export type ProjectManagementCreateIssueData = Omit<JiraCreateIssueRequest, 'issuetype'> & {
    fields: {
        issuetype: { id: BaseIssueTypeName };
    };
};

export type RecallTranscriptWord = {
    text: string;
    start_time: number;
    end_time: number;
};

export enum RecallBotSenderPlatform {
    Unknown = 'unknown',
    Desktop = 'desktop',
    MobileApp = 'mobile_app',
    DialIn = 'dial_in',
}

export type RecallChatEventData = {
    bot_id: string;
    created_at: string;
    to: string; // "everyone" or "only_bot"
    text: string;
    sender: {
        id: number;
        is_host: boolean;
        name: string;
        platform: RecallBotSenderPlatform;
        extra_data: object;
    };
};

export type InterviewQuoteCollection = {
    theme: string;
    quotes: string[];
};

export type KeyResearchTakeawaysJson = {
    participant: string;
    participantInfo: string;
    sessionBackground: {
        purpose: string;
    };
    keyTakeaways: {
        positiveSentiments?: [
            {
                content: string;
                context: string;
            }
        ];
        negativeSentiments?: [
            {
                content: string;
                context: string;
            }
        ];
        sentiment: string;
    };
};

export enum MeetingActionItemKind {
    Followup = 'followup',
    Todo = 'todo',
}

export type ProjectManagementIssueActionCreationData = {
    isCreated?: boolean;
    issueId?: string;
    issueUrl?: string;
};

export type ProjectManagementIssueActionData = ProjectManagementCreateIssueData;

export type ProjectManagementIssueActionMetadata = {
    lables?: string[];
    reporter?: string;
};

export enum ProjectManagementIssueActionType {
    Create = 'create',
}

export type RecallTranscriptSentence = {
    speaker: string | null;
    language: string | null;
    words: RecallTranscriptWord[];
};

export type TranscriptionServiceInformation = {
    transcriptionServiceUsed?: TranscriptionService;
    transcriptionModelUsed?: string;
    transcriptionTierUsed?: string;
    transcriptionWordBoostCount?: number;
    trancriptionCustomSpellingCount?: number;
};

export enum BaseIssueTypeName {
    Bug = 'Bug',
    Task = 'Task',
    SubTask = 'Subtask',
    Story = 'Story',
    Epic = 'Epic',
}

export enum OpenAIEndpoint {
    SPINACH_GPT_EAST = 'SPINACH_GPT_EAST', // East US 2
    SPINACH_GPT_2_EAST = 'SPINACH_GPT_2_EAST', // East US 2
    SPINACH_GPT_3_EAST_2 = 'SPINACH_GPT_3_EAST_2', // East US 2
    SPINACH_GPT_2_NORTH_CENTRAL = 'SPINACH_GPT_2', // North Central US
    SPINACH_GPT_WEST = 'SPINACH_GPT_WEST', // West US
    SPINACH_GPT_2_WEST = 'SPINACH_GPT_2_WEST', // West US
    SPINACH_GPT_3_WEST = 'SPINACH_GPT_3_WEST', // West US
}

export enum AnthropicEndpoint {
    ANTHROPIC_US_EAST_1 = 'us-east-1',
    ANTHROPIC_US_WEST_2 = 'us-west-2',
}

export type OpenAIUsage = {
    tokenUsage: number;
    promptTokenUsage?: number;
    model: string;
    openAiAccountUsed?: string;
    endpoint?: OpenAIEndpoint;
    finishReason?: string;
};

export type ActionsContext = {
    actionItems: InteractiveActionItem[];
    issueActions: ProjectManagementIssueAction[];
    unparsed?: string;
};

export type ProjectManagementIssueAction = Omit<InteractiveActionItem, 'users'> & {
    actionType: ProjectManagementIssueActionType;
    ticketSource: TicketSource;
    accountUrl?: string;
    projectId: string;
    creationData?: ProjectManagementIssueActionCreationData;
    issueStatus: BaseIssueTypeName;
    metadata?: ProjectManagementIssueActionMetadata;

    /** @deprecated only kept for backward compatibility */
    data: ProjectManagementIssueActionData;
};

export type InteractiveActionItem = MeetingActionItem & {
    id: UUID;
    kind: MeetingActionItemKind;
    seriesId: string;
    icpId: string;
    isNotApplicable?: boolean;
    isApproved?: boolean;
    isDeleted?: boolean;
    isSent?: boolean;
    discussion?: {
        hasDiscussion: boolean;
        discussionInitiationUser: string;
        redirectUrl?: string;
    };
    redirectUrl?: string;
};

export enum SlackOutputKind {
    AskSpinach = 'ask-spinach',
    DetailedSummary = 'detailed-summary',
}

export type AskSpianchRealtimeConfig = {
    minimalTranscript: string[];
};

export type AiHistorySlackOutput = {
    channelId: string;
    ts: string;
    channelName?: string;
    kind?: SlackOutputKind;
};

export type AsyncVideoDTO = {
    meetingTitle: string;
    meetingDate: string;
    clips: AsyncVideoClipDTO[];
};

export type AsyncVideoClipDTO = {
    audioUrl: string;
    audioFileKey: string;
} & AsyncVideoObjectBase;

export type AsyncVideoDAO = ({
    audioFileKey: string;
} & AsyncVideoObjectBase)[];

export type AsyncVideoObjectBase = {
    audioDuration: number;
    audioClosedCatptions: {
        characters: string[];
        character_start_times_seconds: number[];
        character_end_times_seconds: number[];
    };
    spokenText: string;
    slide: {
        title: string;
        markdown: string;
    };
};

export enum AiHistoryUserAccessKind {
    Shared = 'shared',
    Attended = 'attended',
}

export enum VideoAgentSessionTopicKind {
    Discussion = 'discussion',
    Person = 'person',
    PreviousBlocker = 'previous_blocker',
}

export type AiHistoryUsersGivenAccess = {
    kind: AiHistoryUserAccessKind;
    spinachUserId: string;
    email: string;
};

export type VideoAgentSessionTopic = {
    id: string;
    title: string;
    startStops: StartStop[];
    kind: VideoAgentSessionTopicKind;
};

export type VideoAgentSessionPhase = VideoAgentSessionTopic;

export type VideoAgentAgenda = {
    topics: VideoAgentSessionTopic[];
    currentTopicId: string | undefined;
};

export type VideoAgentSettings = {
    isRoundtableEnabled?: boolean;
    isFeedbackCollectionEnabled?: boolean;
    isPaused?: boolean;
    isEnabledForChatCommandAudioOutput?: boolean;
    isVoicePrimaryCommandKind?: boolean;
};

export type VideoAgentParticipant = {
    name: string;
    /** Since we are not given unique identifiers for participants on all meeting platforms
     * we make a best effort attempt by combining first join timestamp with name `${name}-${first-join-timestamp}`
     */
    pseudoUniqueId: string;
};

export type VideoAgentPreviousContext = {
    blockers?: MeetingBlocker[];
    actionItems?: MeetingActionItem[];
};

export type VideoAgentChatMessage = {
    sentAt: string;
    text: string;
    sender: {
        name: string;
    };
};

export type Trigger = {
    timestamp: string;
    text: string;
};

type BaseVideoAgentSessionJSON = {
    id: string;
    botId: string;
    hostId: string;
    userIds?: UUID[];
    seriesId: string;
    userFeedback?: string;
    scheduledStartTime: string;
    scheduledEndTime: string;
    pendingIntro?: boolean;

    phases: VideoAgentSessionPhase[];

    agenda: VideoAgentAgenda;

    triggers: Trigger[];

    settings: VideoAgentSettings;

    participants: VideoAgentParticipant[];

    chatMessages: VideoAgentChatMessage[];

    previousContext?: VideoAgentPreviousContext;

    createdAt: string;
    updatedAt: string;
};

/** Extended by client and server types respectively. The specific types should be used instead. */
export type ClientVideoAgentSessionJSON = BaseVideoAgentSessionJSON;
export type NewVideoAgentSessionJSON = Partial<ClientVideoAgentSessionJSON> &
    Pick<ClientVideoAgentSessionJSON, 'botId' | 'seriesId' | 'hostId' | 'scheduledStartTime' | 'scheduledEndTime'>;
export type ServerVideoAgentSessionJSON = BaseVideoAgentSessionJSON;
export type ServerVideoAgentSessionInputJSON = Omit<ServerVideoAgentSessionJSON, 'createdAt' | 'updatedAt'>;
export type VideoAgentChatMessageOptions = {
    /** If we want duplicate-send protection, pass in an operation key here
     * eg we only want the starting agenda instructions to fire once, this should be used in that case
     * eg we want the "next topic" message to fire multiple times a meeting, this should not be used in that case
     */
    oncePerMeetingProtectionKey?: string;
};

export type PatchVideoAgentSessionRequest = {
    updatedSession: ClientVideoAgentSessionJSON;
};

export type PostVideoAgentChatMessageRequest = {
    botId: string;
    message: string;
    options?: VideoAgentChatMessageOptions;
};

export type AiHistoryJSON = {
    seriesId: string;
    sessionId: string;
    userIds: UUID[];
    usersGivenNotesAccess?: AiHistoryUsersGivenAccess[];
    botId: string;
    transcriptionServiceInformation?: TranscriptionServiceInformation;

    avatarSharedState?: AvatarSharedState;

    platform: string;
    slackTeamId?: string;
    slackTeamType?: SlackTeamType;
    createdAt: Date;
    updatedAt: Date;
    meetingType?: ScribeMeetingType;
    icalUid: string;
    meetingTitle: string;
    organizerId?: string;
    hostRootDomain?: string;
    icpId: string;
    enabledSummarySections?: SummaryFeatureIntents[];
    isEmailingIcpOnly?: boolean;

    /** Opted to keep this a string as some statuses may quickly become deprecated */
    status: string;

    /** Opted to use an array for this instead of a map as we could see if duplicated status are created */
    statusHistory: { status: string; dateTime: Date }[];

    actionsContext?: ActionsContext;

    plansAndProgressForTeam?: UserPlansAndProgress[];
    unparsedPlansAndProgressForTeam?: string[];

    usernameEmailMapping?: UserNameWithEmailConfidenceMap;
    userEmailAtlassianIdMapping?: Record<string, string>;

    openAiUsages?: OpenAIUsage[];
    ttsUsageSum?: number;
    ttsUsageCacheHitSum?: number;

    slackSummaryRatings?: { userId: string; actionId: string; feedbackText?: string }[];
    summary?: SummaryJson | null;
    editedSummary?: SummaryJson | null;
    realtimeAtoms?: {
        blockers: MeetingBlocker[];
    };
    asyncVideo?: AsyncVideoDAO;

    /**
     * @NOTE - while the sessionId is the same thing, this fields existence indicates we've
     * stored raw recall data in DB for validation purposes (eg when fetching transcripts) */
    recallMeetingId?: string | null;

    facilitation?: Facilitation;
    realtimeTranscript?: RecallTranscriptSentence[];
    linkedInMeetingHistoryId?: string;
    isPruned?: boolean;
    prunedAt?: Date;

    isPartialCappedSummary?: boolean;
    googleDriveVideoLink?: string;
    isEnabledForEditSummary?: boolean;
};

export type ClientUsersWithAiHistoryAccess = {
    email: string;
    kind: AiHistoryUserAccessKind;
};

export type ClientAiHistory = Pick<
    AiHistoryJSON,
    | 'seriesId'
    | 'botId'
    | 'meetingTitle'
    | 'createdAt'
    | 'summary'
    | 'avatarSharedState'
    | 'isPartialCappedSummary'
    | 'icpId'
> & {
    ticket: {
        expiration: number;
        signature: string;
    };
};

export type MeetingHeySpinachResult = z.infer<typeof MeetingHeySpinachResultSchema>;
export type MeetingHeySpinachTicketCreationResult = z.infer<typeof MeetingHeySpinachTicketCreationResultSchema>;

export type ViewableTranscriptLineData = {
    speaker: string;
    statement: string;
    timestamp: number;
};

export type MeetingClassification = {
    meetingType?: Omit<ScribeMeetingType, 'generic'>;
    meetingId?: string | null;
};

export type MeetingSuggestionResults = {
    recurringEventSuggestions: MeetingClassification[];
    oneOffMeetingSuggestions: MeetingClassification[];
    timedOut?: boolean;
};

export type MeetingEventsAndSuggestions = { events: CalendarEvent[]; suggestions: MeetingSuggestionResults };

export type UserNameWithEmailConfidenceMap = Record<string, { email: string; emailConfidence: number }>;

export type MeetingBlocker = {
    user: string;
    content: string;
    context: string;
    kind?: string;
};

export type MeetingCelebration = {
    user: string;
    content: string;
};

export type MeetingKeyDecision = {
    content: string;
    context: string;
};

export type MeetingWorkstream = {
    name: string;
    description: string;
    tags: string[];
    progress: string;
    owner: string;
    status: string;
};

export type MeetingProject = {
    name: string;
    workstreams: MeetingWorkstream[];
};

export type PreviousMeetingInsight = {
    type: 'blocker' | 'action_item';
    title: string;
    context: string;
    username: string;
    status: 'partially_resolved' | 'not_discussed' | 'resolved';
};

export type VideoAgentSummaryTopic = {
    name: string;
    durationMs: number;
};

export type VideoAgentSummary = {
    roundTableTopic: VideoAgentSummaryTopic[];
    discussionTopic: VideoAgentSummaryTopic[];
};

export type MeetingActionItem = {
    title: string;
    context: string;
    id?: string;
    ticketData?: Pick<DetailedTicket, 'id' | 'ticketUrl' | 'ticketSource' | 'title' | 'displayName'>;
    users: { email?: string; username: string }[];
    speaker?: string;
};

export type UserPlansAndProgress = {
    id: string;
    userName: string;
    email: string;
    plans: string;
    progress: string;
};

export type MeetingGeneratedItem = {
    title: string;
    context: string;
    user?: string;
};

export type MeetingAgendaItem = {
    content: string;
    context: string;
    amount?: string;
};

export type MeetingCustomerSuccessCheckInJSON = {
    callSummary: string;
    highlights: MeetingGeneratedItem[];
    concernsChallenges: MeetingGeneratedItem[];
    requests: MeetingGeneratedItem[];
    bestPractices: MeetingGeneratedItem[];
    keyTakeaways: MeetingGeneratedItem[];
};

export type MeetingGeneralSalesCallJSON = {
    callSummary: string;
    interests: MeetingGeneratedItem[];
    concerns: MeetingGeneratedItem[];
    outcomeAndTakeaway: string;
    agendaItems: MeetingAgendaItem[];
};

export type SummaryJson = {
    attendees: string[];
    blockers?: MeetingBlocker[];
    celebrations?: MeetingCelebration[];
    keyDecisions?: MeetingKeyDecision[];
    actionItems?: MeetingActionItem[];
    agendaItems?: MeetingAgendaItem[];
    plansAndProgress?: UserPlansAndProgress[];
    whatWentWellItems?: MeetingWhatWentWellItem[];
    whatDidNotGoWellItems?: MeetingWhatDidNotGoWellItem[];
    potentialImprovementItems?: MeetingPotentialImprovementItem[];
    tickets?: DetailedTicket[];
    heySpinach?: MeetingHeySpinachResult[];
    slackOutput?: AiHistorySlackOutput[];
    keyResearchTakeaways?: KeyResearchTakeawaysJson;
    researchInterviewQuotesBank?: InterviewQuoteCollection[];
    previousMeetingInsights?: PreviousMeetingInsight[];
    videoAgent?: VideoAgentSummary;
    workstreamProjects?: MeetingProject[];
    generalSalesCallSummary?: MeetingGeneralSalesCallJSON;
    customerSuccessCheckInSummary?: MeetingCustomerSuccessCheckInJSON;
};

export type BotIdPair = {
    botId: string;
    previousBotId?: string;
};

export type SummaryAnalyticsMetadata = Record<
    | `NumberOf${Capitalize<
          keyof Omit<
              SummaryJson,
              'keyResearchTakeaways' | 'videoAgent' | 'customerSuccessCheckInSummary' | 'generalSalesCallSummary'
          >
      >}`
    | 'NumberOfPositiveResearchSentiments'
    | 'NumberOfNegativeResearchSentiments'
    | 'IsCombiningSummaries'
    | 'NumberOfVideoAgentTopics'
    | 'NumberOfTotalWorkstreams'
    | 'NumberOfCustomerSuccessCheckInHighlights'
    | 'NumberOfCustomerSuccessCheckInConcernsChallenges'
    | 'NumberOfCustomerSuccessCheckInRequests'
    | 'NumberOfCustomerSuccessCheckInBestPractices'
    | 'NumberOfCustomerSuccessCheckInKeyTakeaways'
    | 'NumberOfGeneralSalesCallInterests'
    | 'NumberOfGeneralSalesCallConcerns'
    | 'NumberOfGeneralSalesCallAgendaItems',
    number | undefined | boolean
>;

export type MeetingWhatWentWellItem = {
    content: string;
    context: string;
    user: string;
};

export type MeetingWhatDidNotGoWellItem = {
    content: string;
    context: string;
    user: string;
};

export type MeetingPotentialImprovementItem = {
    content: string;
    context: string;
    user: string;
};

export type WorkstreamOverviewEntry = {
    title: string;
    subtopics: string;
    decisions: string;
    summary: string;
    id?: string;
};

export type MeetingWeeklyWorkstream = {
    title: string;
    progress: string;
    decisions: string;
    description: string;
    id?: string;
};

export type WorkstreamsFromMeeting = {
    workstreams: MeetingWeeklyWorkstream[];
};

export type WeeklyWorkstreamOverview = {
    workstreams: WorkstreamOverviewEntry[];
};

const SpinachInsightAction = z.object({
    // Define this schema
});

export enum SpinachInsightKind {
    Gap = 'gap',
    Blocker = 'blocker',
    HeySpinach = 'hey-spinach',
    TimeCheck = 'time-check',
    MeetingTip = 'meeting-tip',
}

export const SpinachInsightSchema = z.object({
    id: z.string(),
    title: z.string(),
    context: z.string(),
    kind: z.string(),
    actions: z.array(SpinachInsightAction),
});

const StartStopSchema = z.object({
    startedAt: z.string(),
    endedAt: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
});

const DiscussionTopicSchema = z.object({
    viewId: z.string(),
    title: z.string(),
    notes: z.array(z.string()),
    startStops: z.array(StartStopSchema),
});

const RoundtableWrapUpSchema = z.object({
    viewId: z.string(),
    startStops: z.array(StartStopSchema),
    insights: z.array(SpinachInsightSchema),
});

export enum ParticipantStatus {
    Online = 'online',
    Offline = 'offline',
}

export const FacilitatedParticipantSchema = z.object({
    viewId: z.string(),
    userId: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    email: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    displayName: z.string(),
    preferedName: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    startStops: z.array(StartStopSchema),
    status: z.nativeEnum(ParticipantStatus), // Replace with the correct type
});

const FacilitatedIcebreakerSchema = z.object({
    viewId: z.string(),
    enabled: z.boolean(),
    currentIndex: z
        .number()
        .nullish()
        .transform((x) => x ?? undefined),
    usedIndexes: z.array(z.number()),
    // ... additional fields for FacilitatedIcebreaker when they become available
});

export const FacilitationEventPersonUpdateCompleteSchema = z.object({
    botId: z.string(),
    previousBotId: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    kind: z.literal('person-update-complete'),
    userDisplayName: z.string(),
    userEmail: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
});

export const FacilitationEventPayloadSchema = z.discriminatedUnion('kind', [
    FacilitationEventPersonUpdateCompleteSchema,
]);

export const FacilitationComputedMetadataSchema = z.object({
    isBotInMeeting: z.boolean(),
});

export const FacilitationSchema = z.object({
    inMeetingInsights: z.array(SpinachInsightSchema),
    discussionTopics: z.array(DiscussionTopicSchema),
    roundtableWrapup: RoundtableWrapUpSchema,
    icebreaker: FacilitatedIcebreakerSchema,
    participants: z.array(FacilitatedParticipantSchema),
    projectedMeetingTime: z.object({
        startTime: z.string(),
        endTime: z.string(),
    }),
    actualMeetingTime: z
        .object({
            startTime: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
            endTime: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
        })
        .nullish()
        .transform((x) => x ?? undefined),
    currentViewId: z.string(),
    meetingTitle: z.string(),
});

export const GenerateGapInsightsRequestBodySchema = z.object({
    userDisplayName: z.string(),
    userEmail: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    currentTranscriptForUser: z.string(), // TODO figure out the format of this
    currentBotId: z.string(),
    seriesId: z.string(),
    previousBotId: z.string(),
});

export type TeamTicketingMap = Record<string, MinimalTicket[]>;

export type UserTicketingMap = {
    changelogHistory: MappedIssueChangeLogHistoryItem[];
    issues: MinimalTicket[];
};

export type GenerateGapInsightsRequestBody = z.infer<typeof GenerateGapInsightsRequestBodySchema>;
export type Facilitation = z.infer<typeof FacilitationSchema>;
export type SpinachInsight = z.infer<typeof SpinachInsightSchema>;
export type FacilitationComputedMetadata = z.infer<typeof FacilitationComputedMetadataSchema>;
export type FacilitatedParticipant = z.infer<typeof FacilitatedParticipantSchema>;
export type DiscussionTopic = z.infer<typeof DiscussionTopicSchema>;
export type FacilitatedStartStop = z.infer<typeof StartStopSchema>;
export type FacilitationEventPayload = z.infer<typeof FacilitationEventPayloadSchema>;
export type FacilitationEventPersonUpdateComplete = z.infer<typeof FacilitationEventPersonUpdateCompleteSchema>;
export type RoundtableWrapup = z.infer<typeof RoundtableWrapUpSchema>;
export type FacilitatedIcebreaker = z.infer<typeof FacilitatedIcebreakerSchema>;
