import moment from 'moment';

import { PUBLIC_DOMAINS, getScribeEmails } from '@spinach-shared/constants';
import {
    AiMeetingCounterKey,
    AiMeetingCounters,
    AutomaticAddScribeToMeetingConfiguration,
    CalendarEvent,
    ClientUserSlackSettings,
    DismissableHints,
    ExperimentKey,
    FeatureFlagValue,
    FeatureToggle,
    IClientUser,
    ISOString,
    IntegrationCode,
    IntegrationLabelMap,
    ScribeMeetingType,
    SendCalendarSuggestionsCohort,
    TICKET_SOURCE_MAP,
    TicketProject,
    TicketSource,
    UUID,
    UserCreationSource,
    UserIdentity,
    UserIdentityPayload,
    UserIntegrationSettings,
    UserMetadata,
    UserSeriesMetadata,
} from '@spinach-shared/types';
import {
    convertHyphenCaseToTitleCase,
    getLowercaseDomainFromEmail,
    getStage,
    getTrialDaysLeft,
    isDemoSeries,
} from '@spinach-shared/utils';

export enum SubscriptionProperty {
    /** @NOTE this is actually used to indicate, from a comms side, whether a user is getting pro features */
    HasProFeaturesOld = 'isPaidAccount',
    HasProFeatures = 'HasProFeatures',
    IsOnPayingAccount = 'IsOnPayingAccount',
    HasBeenOnTrial = 'HasBeenOnTrial',
    IsOnLiveTrial = 'IsOnLiveTrial',
}

export class ClientUser {
    _id: string;
    spinachUserId: UUID;
    email: string;
    zoomUserId?: string;
    stripeCustomerId?: string;
    googleId?: string;
    microsoftId?: string;
    recallZoomAuthCredentialId?: string;
    metadata: UserMetadata;
    seriesMetadataList: UserSeriesMetadata[];
    integrationSettings?: UserIntegrationSettings;
    private _featureToggles?: Record<FeatureToggle, FeatureFlagValue>;

    constructor(props: IClientUser) {
        this._id = props._id;
        this.spinachUserId = props._id;
        this.email = props.email;
        this.seriesMetadataList = props.seriesMetadataList ?? [];
        this.integrationSettings = props.integrationSettings;
        this.zoomUserId = props.zoomUserId;
        this.stripeCustomerId = props.stripeCustomerId;
        this.googleId = props.googleId;
        this.microsoftId = props.microsoftId;
        this._featureToggles = props.featureToggles;
        this.recallZoomAuthCredentialId = props.recallZoomAuthCredentialId;

        this.metadata = { ...props.metadata };
        this.metadata.preferredName = props.metadata?.preferredName ?? props.preferredName;
        this.metadata.companyName = props.metadata?.companyName;
        this.metadata.lastLoggedOn = props.metadata?.lastLoggedOn ?? props.lastLoggedOn;
        this.metadata.lastEditedOn = props.metadata?.lastEditedOn ?? props.lastEditedOn;
        this.metadata.intercomHash = props.metadata?.intercomHash ?? props.intercomHash;
        this.metadata.createdOn = props.metadata?.createdOn ?? props.createdOn;
    }

    get rootDomain(): string {
        return this.metadata.rootDomain || getLowercaseDomainFromEmail(this.email);
    }

    get preferredName(): string {
        return this.metadata.firstName?.trim() && this.metadata.lastName?.trim()
            ? `${this.metadata.firstName.trim()} ${this.metadata.lastName.trim()}`
            : this.metadata.preferredName ?? '';
    }

    get firstName(): string {
        return this.metadata.firstName?.trim() ?? this.metadata.preferredName?.split(' ')[0]?.trim() ?? '';
    }

    get lastName(): string {
        return this.metadata.lastName?.trim() ?? this.metadata.preferredName?.split(' ')[1]?.trim() ?? '';
    }

    get recallCalendarId(): string {
        return this.metadata.recallCalendarId ?? '';
    }

    get isUsingRecallV2(): boolean {
        return !!this.metadata.isUsingRecallCalendarV2;
    }

    get initials(): string {
        return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
    }

    get shouldUseAiBrandingInMeeting() {
        return Boolean(this.featureToggles[FeatureToggle.UseAiBrandingInMeeting]);
    }

    get brandImageId(): string | undefined {
        return this.metadata.brandedImageId ?? undefined;
    }

    get companyName(): string {
        return this.metadata.companyName ?? '';
    }

    get howDidYouHear(): string {
        return this.metadata.howDidYouHear ?? '';
    }

    get howDidYouHearOther(): string {
        return this.metadata.howDidYouHearOther ?? '';
    }

    get isClaimHostEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ClaimHost];
    }

    get integrationsVideo(): string {
        return this.metadata.integrationsVideo ?? '';
    }

    get videoTool(): string {
        if (this.metadata.integrationsVideo && this.metadata.integrationsVideo !== IntegrationCode.Other) {
            return IntegrationLabelMap[this.metadata.integrationsVideo];
        }

        return 'your video tool';
    }

    get isSlackHuddleInAppConnectionEnabled() {
        return this.featureToggles[FeatureToggle.SlackHuddleInAppConnection];
    }

    get isEnabledForSelfServeUserDelete(): boolean {
        return !!this.featureToggles[FeatureToggle.SelfServeUserDelete];
    }

    get dismissedHints(): DismissableHints[] {
        return this.metadata.dismissedHints ?? [];
    }

    get featureToggles(): Record<FeatureToggle, FeatureFlagValue> {
        return (this._featureToggles || {}) as Record<FeatureToggle, FeatureFlagValue>;
    }

    get isAsanaEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.Asana];
    }

    get assignedScribeEmail(): string {
        return this.featureToggles[FeatureToggle.ScribeEmail] as string;
    }

    get isShareAiHistoryEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ShareAIHistory];
    }

    get isActionItemTicketCreationEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.IsActionItemTicketCreationEnabled];
    }

    get isPreviousMeetingsInsightsSectionEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PreviousMeetingsInsightsSection];
    }

    get isAskSpinachInMeetingChatEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.AskSpinachInMeetingChat];
    }

    get isCelloReferralProgramEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.CelloReferralProgram];
    }

    get isPreviousMeetingBriefEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PreviousMeetingBriefMessageInChat];
    }

    get actionItemCreationEnabledTicketSources(): TicketSource[] {
        const enabledTicketSources = this.featureToggles[FeatureToggle.ActionItemCreationEnabledTicketSources];
        if (!enabledTicketSources || !Array.isArray(enabledTicketSources) || !enabledTicketSources.length) {
            return [];
        }
        const validatedEnabledTicketSources = enabledTicketSources.filter((source) =>
            Object.values(TICKET_SOURCE_MAP).includes(source)
        );

        if (!validatedEnabledTicketSources.length) {
            return [];
        }
        return validatedEnabledTicketSources;
    }

    get isEnabledForSupportEditAfterCustomizingSections(): boolean {
        return !!this.featureToggles[FeatureToggle.SupportEditAfterCustomizingSections];
    }

    get isEnabledForCalendarOnboardingStepV2(): boolean {
        return this.featureToggles[FeatureToggle.OnboardingExperiment] === 'calendar-step-v2';
    }

    get isEnabledForMeetingTypeClassification(): boolean {
        return !!this.featureToggles[FeatureToggle.RunSilentMeetingTypeClassificationIntent];
    }

    get isEnabledForAgentOnboarding(): boolean {
        return this.featureToggles[FeatureToggle.OnboardingExperiment] === 'agent-onboarding';
    }

    get isEnabledForNumberOfPeopleInCompanyQuestion(): boolean {
        return !!this.featureToggles[FeatureToggle.NumberOfPeopleInCompanyQuestion];
    }

    get shouldShowManualInviteInAgentOnboarding(): boolean {
        const bigEnoughCompanySizes = ['10000+', '1000-10000'];
        const usersCompanySize = this.metadata.numberOfPeopleInCompany;

        if (this.featureToggles[FeatureToggle.OnboardWithoutCalendarIntegration]) {
            return true;
        }

        return (
            !!usersCompanySize &&
            bigEnoughCompanySizes.includes(usersCompanySize) &&
            !(this.isAuthedForGoogle || this.isAuthedForMicrosoftCalendar)
        );
    }

    get isMeetingInformationTooltipsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.MeetingInformationTooltips];
    }

    get isEnabledForViralityLandingV1(): boolean {
        return this.featureToggles[FeatureToggle.ViralityLandingPage] === 'v1';
    }

    get isEnabledForOnboardingExperimentOne(): boolean {
        const ELIGIBLE_CREATION_SOURCES = [
            UserCreationSource.GoogleSignInV2,
            UserCreationSource.MicrosoftSignIn,
            UserCreationSource.MicrosoftSignInFromCompanyWebsite,
        ];
        const isEligibleCreationSource =
            !!this.metadata.creationSource && ELIGIBLE_CREATION_SOURCES.includes(this.metadata.creationSource);

        return this.featureToggles[FeatureToggle.OnboardingExperiment] === 'v1' && isEligibleCreationSource;
    }

    get isAskSpinachSlackEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.AskSpinachSlack];
    }

    get isChurnIndicationEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ChurnIndicationOnJobComplete];
    }

    get isEnabledForScimAccess(): boolean {
        return !!this.featureToggles[FeatureToggle.ScimAccess];
    }

    get isRealtimeAskSpinachEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.RealtimeAskSpinach];
    }

    get isMultiMeetingAskSpinachEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.MultiMeetingAskSpinach];
    }

    get multiMeetingAskSpinachNumberOfPastMeetings(): number {
        return (this.featureToggles[FeatureToggle.multiMeetingAskSpinachNumberOfPastMeetings] || 4) as number;
    }

    get isEmailCtaForAsyncVideoEnabled(): boolean {
        return (
            !!this.featureToggles[FeatureToggle.GenerateAsyncVideoWithSummary] &&
            !!this.featureToggles[FeatureToggle.EmailCtaForAsyncVideo]
        );
    }

    get isBlockedFromSSO(): boolean {
        if (this.featureToggles[FeatureToggle.BypassSSOLoginDeactivation]) {
            return false;
        }

        return this.metadata.isScimActive === false;
    }

    get isSwappingEmailSummaryForNotification(): boolean {
        return !!this.featureToggles[FeatureToggle.SwapEmailSummaryForNotification];
    }

    get isEnabledForIndividualSummaryTemplateV2(): boolean {
        return !!this.featureToggles[FeatureToggle.IndividualSummaryEmailTemplateV2];
    }

    get shouldPaginateConfluenceSpaces(): boolean {
        return !!this.featureToggles[FeatureToggle.PaginateConfluenceSpaces];
    }

    get automaticAddScribeConfiguration(): AutomaticAddScribeToMeetingConfiguration | undefined {
        return this.metadata.automaticAddScribeConfiguration ?? undefined;
    }

    get confluenceSpacePaginationTimeoutInMilliseconds(): number {
        return this.featureToggles[FeatureToggle.confluenceSpacePaginationTimeoutInMilliseconds] as number;
    }

    get authedValidTicketCreationSources(): TicketSource[] {
        const enabledTicketSources = this.actionItemCreationEnabledTicketSources;
        return this.authedTicketSources.filter((source) => {
            if (enabledTicketSources.includes(source)) {
                if (source === TICKET_SOURCE_MAP.Jira) {
                    return this.integrationSettings?.jiraSettings?.hasCreateScopes;
                }
                return true;
            }
            return false;
        });
    }

    get mixpanelAdminEmbedUrls(): string[] {
        return (this.featureToggles[FeatureToggle.MixpanelAdminEmbedUrls] || []) as string[];
    }

    get isUserAuthedWithAValidTicketCreationSource(): boolean {
        if (!this.isAuthedForAnyTicketProvider) {
            return false;
        }
        return !!this.authedValidTicketCreationSources.length;
    }

    get authedTicketSources(): TicketSource[] {
        return Object.values(TICKET_SOURCE_MAP).filter((source) => this.isAuthedForTicketSource(source));
    }

    get defaultOutputLanguage(): string | undefined {
        return this.metadata.defaultOutputLanguage;
    }

    get isEmailingHostOnly(): boolean {
        return !!this.metadata.isEmailingIcpOnly;
    }

    get isEnabledForEditSummary(): boolean {
        return !!this.featureToggles[FeatureToggle.EditSummaryMVP] || !!this.metadata.isEditingAiEmailsByDefault;
    }

    get isEnabledForChunkBasedBlockers(): boolean {
        return !!this.featureToggles[FeatureToggle.ChunkBasedBlockers];
    }

    get isEnabledForChainedActionItemGrouping(): boolean {
        return !!this.featureToggles[FeatureToggle.ChainedActionItemGrouping];
    }

    get isEnabledForChainedBlockerGrouping(): boolean {
        return !!this.featureToggles[FeatureToggle.ChainedBlockerGrouping];
    }

    get isEnabledForFullTranscriptPlansAndProgress(): boolean {
        return !!this.featureToggles[FeatureToggle.FullTranscriptPlansAndProgress];
    }

    get isEnabledForPostGenerationPerPersonTicketMatching(): boolean {
        return !!this.featureToggles[FeatureToggle.PostGenerationPerPersonTicketMatching];
    }

    get isOnboarded(): boolean {
        return !!this.metadata.isOnboarded;
    }

    /**
     * @description this returns true in the following situations
     * 1. The user is enabled for the global RecallV2Auth flag
     *    a. This flag controls, at a global level, whether users (both new and existing)
     *       will receive recall v2 when they authenticate
     * 2. The user is enabled for RecallV2Onboarding, and they are using recall v2
     *    a. This evaluation targets only users who were onboarded to Recall V2 from the get-go
     *       this will not evaluate to true for existing users who are not using Recall V2
     * 3. The user is enabled for RecallV2Onboarding and they have not yet finished onboarding
     *    a. This ensures that users, at any point in the onboarding process, can upgrade to Recall V2
     *       even if their user has already been created.
     *    b. This helps catch users who came from the company site, as they use a different auth URL,
     *       and will already have a user created with isUsingRecallV2 set to false when they get to
     *       the onboarding flow. At which point they can upgrade their permissions to Recall V2 if they so choose
     */
    get isEnabledForRecallV2(): boolean {
        return (
            !getScribeEmails(getStage()).includes(this.email) &&
            (!!this.featureToggles[FeatureToggle.RecallV2Auth] ||
                (this.isEnabledForRecallV2Onboarding && !!this.isUsingRecallV2) ||
                (this.isEnabledForRecallV2Onboarding && !this.isOnboarded))
        );
    }

    get isEnabledForRecallV2Onboarding(): boolean {
        return !!this.featureToggles[FeatureToggle.RecallV2Onboarding];
    }

    get isEnabledForAddToAllMeetings(): boolean {
        return !!this.featureToggles[FeatureToggle.AddToAllMeetings] && this.isEnabledForRecallV2;
    }

    get isEnabledForCustomerSuccessCheckInMeetingType(): boolean {
        return !!this.featureToggles[FeatureToggle.IncludeCustomerSuccessCheckInInMeetingTypeSelection];
    }

    get isEnabledForGeneralSalesMeetingType(): boolean {
        return !!this.featureToggles[FeatureToggle.IncludeGeneralSalesMeetingInMeetingTypeSelection];
    }

    get isConfluenceUserMentionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ConfluenceUserMentions];
    }

    get isIndividualEmailEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SendIndividualEmails];
    }

    get isEnabledForParticipantOverviewEmail(): boolean {
        return !!this.featureToggles[FeatureToggle.ParticipantOverviewEmail];
    }

    get isEnabledForProvisioningEmailSection(): boolean {
        return !!this.featureToggles[FeatureToggle.ProvisioningEmailSection];
    }

    get isPasteSiteSelectionEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PasteAtlassianSiteSelection];
    }

    get isConfluenceSpaceSelectionEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ConfluenceSpaceSelection];
    }

    get maxAttendeesForPerPersonJiraTicketFetch(): number {
        return this.featureToggles[FeatureToggle.PerPersonJiraTicketFetchMaxAttendees] as number;
    }

    get isEnabledForPerPersonJiraTicketFetch(): boolean {
        return !!this.featureToggles[FeatureToggle.PerPersonJiraTicketFetch];
    }

    get isFreeTierLimited(): boolean {
        return !!this.featureToggles[FeatureToggle.FreeTierLimited];
    }

    get isAiQualityLimited(): boolean {
        return !!this.featureToggles[FeatureToggle.LimitAIQuality];
    }

    get isCalendarSuggestionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.CalendarSuggestions];
    }

    get isEnabledForCombinedSummaries(): boolean {
        return (
            !!this.featureToggles[FeatureToggle.CombineInMeetingAndAiMeeting] ||
            !!this.featureToggles[FeatureToggle.CombinedGenericSummaries]
        );
    }

    get isUsingShortenedOnobarding(): boolean {
        return !!this.featureToggles[FeatureToggle.ShortenedOnboardingFlow];
    }

    get calendarSuggestionCohort(): SendCalendarSuggestionsCohort {
        return this.featureToggles[FeatureToggle.CalendarSuggestions] as SendCalendarSuggestionsCohort;
    }

    get isPublicFollowupsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackPublicFollowups];
    }

    get isPrivateIssueActionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackDMIssueActions];
    }

    get isSlackPrivateFollowUpsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackDMFollowUpsEnabled];
    }

    get isPublicIssueActionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackPublicIssueActions];
    }

    get useEnhancedDiarization(): boolean {
        return !!this.featureToggles[FeatureToggle.EnhancedDiarizationEnabled];
    }

    get talkToSalesUrl(): string {
        return (this.featureToggles[FeatureToggle.TalkToSalesURL] || '') as string;
    }

    get isPayingUser(): boolean {
        return (
            !!this.metadata.isPaidAi ||
            !!this.featureToggles[FeatureToggle.ProAccount] ||
            !!this.featureToggles[FeatureToggle.EnterpriseAccounts]
        );
    }

    get hasProFeatures(): boolean {
        return this.isPayingUser || this.isOnLiveReverseTrial;
    }

    get isTemporaryLinkToAsyncVideo(): boolean {
        return !!this.featureToggles[FeatureToggle.TemporaryLinkToAsyncVideo];
    }

    get isOnManuallyManagedTrial(): boolean {
        return !!this.featureToggles[FeatureToggle.ProTrialAccounts];
    }

    get isPreMeetingNotificationEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PreMeetingFacilitationNotification];
    }

    get shouldFetchSeriesFeatureFlags(): boolean {
        return !!this.featureToggles[FeatureToggle.ShouldFetchSeriesFeatureFlags];
    }

    get dataRetentionInDays(): number {
        return this.featureToggles[FeatureToggle.DataRetentionExpirationInDays] as number;
    }

    get aiSubscriptionMetadata() {
        return {
            [SubscriptionProperty.HasProFeaturesOld]: this.hasProFeatures,
            [SubscriptionProperty.HasProFeatures]: this.hasProFeatures,
            [SubscriptionProperty.IsOnPayingAccount]: this.isPayingUser,
            [SubscriptionProperty.HasBeenOnTrial]:
                !!this.metadata.accountReverseTrialStartDate || !!this.metadata.personalReverseTrialStartDate,
            [SubscriptionProperty.IsOnLiveTrial]: !!this.isOnLiveReverseTrial,
        };
    }

    /** @NOTE - only to be used in the rarer cases where its important to distinguish between isPaidAccount and Trialing, like a Trial UX change */
    get isOnLiveReverseTrial(): boolean {
        if (this.isOnManuallyManagedTrial) {
            return true;
        }

        const daysLeft = this.reverseTrialDaysLeft;

        const isPayingUser = this.isPayingUser;

        return daysLeft > 0 && !!this.featureToggles[FeatureToggle.ReverseTrialAiEnabled] && !isPayingUser;
    }

    get reverseTrialStartDate(): Date | undefined {
        return this.metadata.accountReverseTrialStartDate || this.metadata.personalReverseTrialStartDate;
    }

    get reverseTrialDaysLeft(): number {
        const trialStart = this.reverseTrialStartDate;
        const trialLength = this.reverseTrialLengthInDays;

        // manually managed trials should not rely on trial length in general
        // but just to be safe, treat length as fresh duration.
        if (this.isOnManuallyManagedTrial) {
            return trialLength;
        }

        return getTrialDaysLeft(trialStart, trialLength);
    }

    get reverseTrialLengthInDays(): number {
        return (this.featureToggles[FeatureToggle.ReverseTrialLengthInDays] || 0) as number;
    }

    get limitedCycleStartDateOverride(): Date | undefined {
        const overrideDateString = this.featureToggles[FeatureToggle.LimitedCycleStartDateOverride];

        if (!overrideDateString) {
            return undefined;
        }

        if (typeof overrideDateString !== 'string') {
            return undefined;
        }

        // should be in the format of MM/DD/YYYY - easier for non-technical teammates to manage in LD
        return new Date(overrideDateString);
    }

    get catchAllLimitedCycleStartDate(): Date | undefined {
        const defaultDateString = '2024-06-24';
        const catchAllDateString =
            this.featureToggles[FeatureToggle.CatchAllLimitedCycleStartDate] || defaultDateString;

        if (!catchAllDateString) {
            return new Date(defaultDateString);
        }

        if (typeof catchAllDateString !== 'string') {
            return new Date(defaultDateString);
        }

        // should be in the format of YYYY-MM-DD
        return new Date(catchAllDateString);
    }

    get personalUsageHours(): string {
        if (!this.metadata.personalUsageForCycleInSeconds) {
            return '0';
        }

        return (this.metadata.personalUsageForCycleInSeconds / 60 / 60).toFixed(2);
    }

    get accountUsageHours(): string {
        if (!this.metadata.accountUsageForCycleInSeconds) {
            return '0';
        }

        return (this.metadata.accountUsageForCycleInSeconds / 60 / 60).toFixed(2);
    }

    get isEnabledForUsageComputation(): boolean {
        return !!this.featureToggles[FeatureToggle.UsageComputation];
    }

    get hasAdminMixpanelEmbedAccess(): boolean {
        return !!this.featureToggles[FeatureToggle.AdminMixpanelEmbedAccess];
    }

    get hasAdminUserContentAccess(): boolean {
        return !!this.featureToggles[FeatureToggle.AdminUserContentAccess];
    }

    /** This is intended to be an OR combination of admin features.
     * if the user has at least one of them they should be able to access the dashboard.
     */
    get hasAdminSectionAccess(): boolean {
        return this.hasAdminMixpanelEmbedAccess || this.hasAdminUserContentAccess;
    }

    get isEnabledForDraftsSection(): boolean {
        return !!this.featureToggles[FeatureToggle.DraftsSection];
    }
    get isEnabledForUsageVisibility(): boolean {
        return this.isEnabledForUsageComputation && !!this.featureToggles[FeatureToggle.UsageVisibility];
    }

    get usageDefaultCycleWindow(): number {
        return this.featureToggles[FeatureToggle.UsageDefaultCycleDays] as number;
    }

    get selectedSlackAsMessagingIntegraton(): boolean {
        return this.integrationsMessaging === IntegrationCode.Slack;
    }

    get integrationsMessaging(): string {
        return this.metadata.integrationsMessaging ?? '';
    }

    get integrationsProjectMgmt(): string[] {
        return this.metadata.integrationsProjectMgmt ?? [];
    }

    get integrationsVideoOther(): string {
        return this.metadata.integrationsVideoOther ?? '';
    }

    get integrationsMessagingOther(): string {
        return this.metadata.integrationsMessagingOther ?? '';
    }

    get integrationsProjectMgmtOther(): string {
        return this.metadata.integrationsProjectMgmtOther ?? '';
    }

    get createdOn(): ISOString {
        return this.metadata.createdOn!;
    }

    get realSeries(): UserSeriesMetadata[] {
        return this.seriesMetadataList.filter((series) => !isDemoSeries(series.id));
    }

    get isOnFirstPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 0;
    }

    get isOnSecondPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 1;
    }

    get isAnonymous(): boolean {
        return !!this.metadata.isAnonymousUser;
    }

    get isUseManualScribeVideoOutput(): boolean {
        return !!this.featureToggles[FeatureToggle.UseManualScribeVideoOutput];
    }

    get isHeySpinachCreateTicketEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.HeySpinachCreateTicketEnabled];
    }

    get isCustomBrandedImageEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.CustomBotBranding];
    }

    get pauseIsPaidFeatureMessage(): string | undefined {
        return this.featureToggles[FeatureToggle.InVideoMeetingPauseIsPaidFeatureMessageText] as string | undefined;
    }

    get shouldSendPauseIsPaidFeatureMessageInChat(): boolean {
        return !!this.featureToggles[FeatureToggle.InVideoMeetingPauseIsPaidFeatureMessage];
    }

    get isAuthedForNotion(): boolean {
        return !!this.integrationSettings?.notionSettings?.isAuthed;
    }

    get isAuthedForConfluence(): boolean {
        return !!this.integrationSettings?.confluenceSettings?.isAuthed;
    }

    get isAuthedForJira(): boolean {
        return !!this.integrationSettings?.jiraSettings?.isAuthed;
    }

    get isAuthedForTrello(): boolean {
        return !!this.integrationSettings?.trelloSettings?.isAuthed;
    }

    get isAuthedForMondayDotCom(): boolean {
        return !!this.integrationSettings?.mondayDotComSettings?.isAuthed;
    }

    get isAuthedForAsana(): boolean {
        return !!this.integrationSettings?.asanaSettings?.isAuthed;
    }

    get isAuthedForLinear(): boolean {
        return !!this.integrationSettings?.linearSettings?.isAuthed;
    }

    get isAuthedForClickUp(): boolean {
        return !!this.integrationSettings?.clickUpSettings?.isAuthed;
    }

    get isEnabledForLiveNotesV2(): boolean {
        return !!this.featureToggles[FeatureToggle.UseLiveNotesV2];
    }

    get isEnabledForTopicObjective(): boolean {
        return !!this.featureToggles[FeatureToggle.AgentTopicObjective];
    }

    get scheduleOnboardingSessionUrl(): string {
        return this.featureToggles[FeatureToggle.ScheduleOnboardingSessionUrl] as string;
    }

    /**
     * @NOTE this could be written to be more abstract to
     * follow the integrationSettings type (e.g. `${TicketSource}}Settings`
     * but was not to try and create more readable and stable code
     *
     * This means, however, that when a new ticket source is added that this
     * method should be updated to include it, otherwise the default case
     * will be returned
     *
     * Using the more dynamic approach would also mean that TS would catch this
     * case for us
     * */
    isAuthedForTicketSource(ticketSource: TicketSource): boolean {
        switch (ticketSource) {
            case TICKET_SOURCE_MAP.Jira:
                return this.isAuthedForJira;
            case TICKET_SOURCE_MAP.Linear:
                return this.isAuthedForLinear;
            case TICKET_SOURCE_MAP.ClickUp:
                return this.isAuthedForClickUp;
            case TICKET_SOURCE_MAP.Asana:
                return this.isAuthedForAsana;
            case TICKET_SOURCE_MAP.Trello:
                return this.isAuthedForTrello;
            case TICKET_SOURCE_MAP.MondayDotCom:
                return this.isAuthedForMondayDotCom;
            default:
                return false;
        }
    }

    ticketSourceProject(ticketSource: TicketSource): TicketProject | undefined {
        if (!this.isAuthedForTicketSource(ticketSource)) {
            return undefined;
        }
        switch (ticketSource) {
            case TICKET_SOURCE_MAP.Jira:
                return this.integrationSettings?.jiraSettings?.project;
            case TICKET_SOURCE_MAP.Asana:
                return this.integrationSettings?.asanaSettings?.project;
            case TICKET_SOURCE_MAP.Linear:
                return this.integrationSettings?.linearSettings?.project;
            case TICKET_SOURCE_MAP.ClickUp:
                return this.integrationSettings?.clickUpSettings?.project;
            case TICKET_SOURCE_MAP.Trello:
                return this.integrationSettings?.trelloSettings?.project;
            case TICKET_SOURCE_MAP.MondayDotCom:
                return this.integrationSettings?.mondayDotComSettings?.project;
            default:
                return undefined;
        }
    }

    ticketSourceSubProject(ticketSource: TicketSource): TicketProject | undefined {
        if (!this.isAuthedForTicketSource(ticketSource)) {
            return undefined;
        }
        switch (ticketSource) {
            case TICKET_SOURCE_MAP.ClickUp:
                return this.integrationSettings?.clickUpSettings?.subProject;
            case TICKET_SOURCE_MAP.Trello:
                return this.integrationSettings?.trelloSettings?.subProject;
            case TICKET_SOURCE_MAP.MondayDotCom:
                return this.integrationSettings?.mondayDotComSettings?.subProject;
            default:
                return undefined;
        }
    }

    get isAuthedForAnyTicketProvider(): boolean {
        return (
            !!this.isAuthedForJira ||
            !!this.isAuthedForLinear ||
            !!this.isAuthedForClickUp ||
            !!this.isAuthedForAsana ||
            !!this.isAuthedForTrello
        );
    }

    /** @note We use the bot token for Slack integration, so there is no `isAuthed` value here */
    get isAuthedForSlack(): boolean {
        return !!this.integrationSettings?.slackSettings;
    }

    get isAuthedForGoogleDrive(): boolean {
        return (
            !!this.integrationSettings?.googleSettings?.isAuthed &&
            !!this.integrationSettings?.googleSettings?.isDriveEnabled
        );
    }

    get isAuthedForGoogleCalendar(): boolean {
        return (
            !!this.integrationSettings?.googleSettings?.isAuthed &&
            !!this.integrationSettings?.googleSettings?.isCalendarEnabled
        );
    }

    get isAuthedForMicrosoftCalendar(): boolean {
        return (
            !!this.integrationSettings?.microsoftSettings?.isAuthed &&
            !!this.integrationSettings?.microsoftSettings?.isCalendarEnabled
        );
    }

    get calendarProvider(): 'google' | 'microsoft' | 'unknown' {
        if (this.isAuthedForGoogleCalendar) {
            return 'google';
        } else if (this.isAuthedForMicrosoftCalendar) {
            return 'microsoft';
        } else {
            return 'unknown';
        }
    }

    get isAuthedForAnyCalendar(): boolean {
        return this.isAuthedForGoogleCalendar || this.isAuthedForMicrosoftCalendar;
    }

    get isAuthedForGoogle(): boolean {
        return !!this.integrationSettings?.googleSettings?.isAuthed;
    }

    get isAuthedForMicrosoft(): boolean {
        return !!this.integrationSettings?.microsoftSettings?.isAuthed;
    }

    get slackSettings(): ClientUserSlackSettings | undefined {
        return this.integrationSettings?.slackSettings;
    }

    get shouldAuthBeforeDemo(): boolean {
        return this.metadata.signupBeforeDemo === true;
    }

    get isDemoing(): boolean {
        return (
            this.metadata.isAnonymousUser === true ||
            (this.shouldAuthBeforeDemo && !this.metadata.isOnboarded && this.metadata.isAnonymousUser === false)
        );
    }

    get isScribeOnlyUser(): boolean {
        const isPrioritizedForStandupApp = this.featureToggles[FeatureToggle.ForceStandupAppOnboarding];

        return !!this.metadata.experimentCodes?.includes(ExperimentKey.StandupScribe) && !isPrioritizedForStandupApp;
    }

    get isForcedLegacyOnboarding(): boolean {
        return !!this.featureToggles[FeatureToggle.ForceStandupAppOnboarding];
    }

    get isHidingAiDashboard(): boolean {
        return !!this.featureToggles[FeatureToggle.HideAiDashboard];
    }

    get defaultChannelSelection(): { label: string; code: string; isPrivate?: boolean } | null {
        if (this.isAuthedForSlack && this.slackSettings?.defaultChannelId && this.slackSettings?.defaultChannelName) {
            return {
                label: this.slackSettings.defaultChannelName,
                code: this.slackSettings.defaultChannelId,
                isPrivate: !!this.slackSettings.isDefaultChannelPrivate,
            };
        }
        return null;
    }

    get totalAiSessionCount(): number {
        return this.metadata.totalAiSessionCount || 0;
    }

    get isUnprovisionedAndBlockedFromAiProcessing(): boolean {
        return !!this.featureToggles[FeatureToggle.BlockUnprovisionedUser] && !this.metadata.isScimActive;
    }

    get isSummaryProcessingDisabled(): boolean {
        // if atlassian user and not provisioned and feature toggle for this specifically is on, disable
        if (this.isUnprovisionedAndBlockedFromAiProcessing) {
            return true;
        }

        if (this.featureToggles[FeatureToggle.HideAiDashboard]) {
            return true;
        }

        if (this.isPayingUser) {
            return false;
        }

        if (this.isExceedingUsageLimits && !this.isPartialCappingEnabled) {
            return true;
        }

        const disabledByLdFlag = !!this.featureToggles[FeatureToggle.DisableScribeProcessing];

        return disabledByLdFlag || this.isPersonalTrialExpired;
    }

    get isEmbeddingUsageForSummaryGenerationDisabled(): boolean {
        return !!this.featureToggles[FeatureToggle.DisableEmbeddingForSummaryGeneration];
    }

    get isPersonalTrialExpired(): boolean {
        if (this.isOnManuallyManagedTrial) {
            return false;
        }

        return !!this.metadata.personalReverseTrialStartDate && this.reverseTrialDaysLeft === 0;
    }

    /**
     * Returns a `Date` corresponding to the user's limited cycle start date if and only if they are eligible for auto capping.
     * This may be a manually set date based on Operations or based on when their reverse trial ended.
     * Examples of ineligibility are Personal users, Paying users, or in-trial users
     * Ineligibility for auto-capping corresponds to a `null` return value;
     */
    get autoCappingCycleStartDate(): Date | null {
        if (!this.isAutoCappingEnabled) {
            return null;
        }

        // personal users are currently excluded from usage limits
        // as they are capped entirely when limited
        // since we've already wired up the syncing mechanism below for them, we'll keep it
        // when we want to experiment with usage capping for personal users as well in the future.
        if (this.isPersonal) {
            return null;
        }

        // usage limits does not apply to users with pro features or paying users
        if (this.isPayingUser || this.hasProFeatures) {
            return null;
        }

        // if the user / account has an override that has yet to occur,
        // theyre not yet eligible. perhaps karin set them up in advance.
        // if have an override that's before this moment, then they are eligible
        const startDateOverride = this.limitedCycleStartDateOverride;
        const now = moment();
        const hasOverrideThatsPassed = !!startDateOverride && moment(startDateOverride).isBefore(now);

        if (hasOverrideThatsPassed) {
            return startDateOverride;
        }

        if (this.reverseTrialStartDate) {
            const accountTrialStart = moment(this.reverseTrialStartDate);

            // accounts that started on or after 4/1/2024 will simply use the end of their trial date as limited cycle start per Operations
            const momentOfAprilFirst2024 = moment().month(3).date(1).year(2024).hours(0).minutes(0).seconds(0);
            const isTrialStartAfterAprilFirst = accountTrialStart.isAfter(momentOfAprilFirst2024);

            if (isTrialStartAfterAprilFirst) {
                return moment(accountTrialStart).add(this.reverseTrialLengthInDays, 'days').toDate();
            }
        }

        /**
         * for everyone else, use a cycle start date of 2024-06-24
         * if that has passed, return true, otherwse return false
         */
        const catchAllStartDate = this.catchAllLimitedCycleStartDate;
        const hasCatchAllStartDatePassed = !!catchAllStartDate && moment(catchAllStartDate).isBefore(now);

        if (hasCatchAllStartDatePassed) {
            return catchAllStartDate;
        }

        return null;
    }

    get usageHoursForCurrentLimitedCycle(): number {
        if (!this.metadata.usageHoursForCurrentLimitedCycle) {
            return 0;
        }

        return this.metadata.usageHoursForCurrentLimitedCycle;
    }

    get isExceedingUsageLimits(): boolean {
        if (!this.autoCappingCycleStartDate) {
            return false;
        }

        return (
            !!this.usageHoursForCurrentLimitedCycle &&
            this.usageHoursForCurrentLimitedCycle > this.hourlyUsageLimitPerCycle
        );
    }

    get isEligibleForPartialCappedSummary(): boolean {
        return this.isPartialCappingEnabled && this.isExceedingUsageLimits;
    }

    get isPartialCappingEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PartialCapping];
    }

    get hideMeetingVideosOverride(): boolean {
        return !!this.featureToggles[FeatureToggle.HideMeetingVideosOverride];
    }

    get hideMeetingTranscriptsOverride(): boolean {
        return !!this.featureToggles[FeatureToggle.HideMeetingTranscriptsOverride];
    }

    get shouldHideMeetingVideos(): boolean {
        return Boolean(
            this.hideMeetingVideosOverride ||
                (this.metadata.shouldHideMeetingVideos && this.isToggleMeetingVideosEnabled)
        );
    }

    get isToggleMeetingVideosEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ToggleMeetingVideo];
    }

    get isToggleMeetingTranscriptsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.ToggleMeetingTranscripts];
    }

    get shouldHideMeetingTranscripts(): boolean {
        return Boolean(
            this.hideMeetingTranscriptsOverride ||
                (this.metadata.shouldHideMeetingTranscripts && this.isToggleMeetingTranscriptsEnabled)
        );
    }

    get isGenericCombinedSummaryEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.CombinedGenericSummaries];
    }

    get isPersonal(): boolean {
        return PUBLIC_DOMAINS.includes(this.rootDomain);
    }

    get isBusiness(): boolean {
        return !PUBLIC_DOMAINS.includes(this.rootDomain);
    }

    /** @deprecated */
    get isEnabledForAgent(): boolean {
        return !!this.featureToggles[FeatureToggle.Avatar];
    }

    get hourlyUsageLimitPerCycle(): number {
        return (this.featureToggles[FeatureToggle.HourlyUsageLimitPerCycle] || 5) as number;
    }

    // this is a master switch for the auto capping mechanism.
    // autoCappingCycleStartDate should be used for logical gating instead of this
    get isAutoCappingEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.AutoCappingStarters];
    }

    get isSkippingEmbeddingCreation(): boolean {
        return !!this.featureToggles[FeatureToggle.SkipEmbeddingCreation];
    }

    get isEnabledForHostNotesDeletion(): boolean {
        return !!this.featureToggles[FeatureToggle.SelfServeHostDeleteMeetingNotes];
    }

    get isEnabledForVideoAgentAgenda(): boolean {
        return !!this.featureToggles[FeatureToggle.VideoAgentAgenda];
    }

    get isEnabledForPreparedAgentTopics(): boolean {
        return (
            !!this.featureToggles[FeatureToggle.VideoAgentAgenda] &&
            !!this.featureToggles[FeatureToggle.CustomFeatureSeriesSettings]
        );
    }

    get isEnabledForVideoAgentViaRecallV2(): boolean {
        return !!this.featureToggles[FeatureToggle.VideoAgentAgendaRecallV2];
    }

    get isEnabledForRealtimeV2BotDedup(): boolean {
        return !!this.featureToggles[FeatureToggle.RealtimeRecallV2BotDeduplication];
    }

    get realtimeV2BotDedupDelayInSeconds(): number {
        return this.featureToggles[FeatureToggle.RealtimeRecallV2BotDeduplicationDelayInSeconds] as number;
    }

    get isEnabledForAgentAudioUponChatCommands(): boolean {
        return !!this.featureToggles[FeatureToggle.AgentAudioUponChatCommands];
    }

    get isEnabledForAutoAgent(): boolean {
        return (
            !!this.metadata.isAccountOnboardedWithAgent &&
            !this.featureToggles[FeatureToggle.BlockAgentForAgentOnboardedAccount]
        );
    }

    get isEnabledForFullStorySessionRecording(): boolean {
        return !!this.featureToggles[FeatureToggle.RecordSessionsOnFullStory];
    }

    areIntegrationsUpdated(latestIntegrations: UserIntegrationSettings | undefined) {
        const serializedLatest = JSON.stringify(latestIntegrations);
        const serializedPrevious = JSON.stringify(this.integrationSettings);
        return serializedLatest !== serializedPrevious;
    }

    getSeriesById(seriesId: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.id === seriesId);
    }

    getSeriesByName(seriesName: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.name === seriesName);
    }

    getSeriesIdOfOnlySeries(): string | undefined {
        if (this.realSeries.length === 1) {
            return this.realSeries[0].id;
        } else {
            return undefined;
        }
    }

    isUserTheOrganizer(event: CalendarEvent): boolean {
        return event.organizer?.email?.toLocaleLowerCase() === this.email.toLocaleLowerCase();
    }

    // Should we really be passing all this information back and forth?
    toUserIdentityPayload(): UserIdentityPayload {
        return {
            Email: this.email,
            UserId: this.spinachUserId,
            FirstName: this.firstName,
            LastName: this.lastName,
            RootDomain: this.rootDomain,
            Company: this.companyName,
            HowDidYouHearSource: this.howDidYouHear,
            UserName: this.preferredName,
            UserMetadata: this.metadata,
            FeatureToggles: this.featureToggles,
            IsPersonalUser: PUBLIC_DOMAINS.includes(this.rootDomain),
        };
    }

    getUpdatedAiHistory(meetingType: ScribeMeetingType | undefined): AiMeetingCounters {
        const meetingCounters: Required<AiMeetingCounters> = {
            totalAiSessionCount: this.metadata.totalAiSessionCount ?? 0,
        };

        Object.values(ScribeMeetingType).forEach((mtgType) => {
            // turn backlog-grooming into BacklogGrooming and standup into Standup
            const keyPart = convertHyphenCaseToTitleCase(mtgType);

            const key: AiMeetingCounterKey = `totalAi${keyPart}SessionCount`;
            meetingCounters[key] = (this.metadata as Record<string, any>)[key] ?? 0;

            if (meetingType === mtgType && meetingCounters[key] !== undefined) {
                meetingCounters[key]!++;
            }
        });

        /** Should exist, this is making TS happy */
        if (meetingCounters.totalAiSessionCount !== undefined) {
            meetingCounters.totalAiSessionCount++;
        }

        return meetingCounters;
    }

    toUserIdentity(): UserIdentity {
        return {
            userId: this.spinachUserId,
            firstName: this.firstName,
            lastName: this.lastName,
            company: this.companyName,
            rootDomain: this.rootDomain,
            howDidYouHearSource: this.howDidYouHear,
            userName: this.preferredName,
            userEmail: this.email,
            seriesMetadataList: this.seriesMetadataList,
            metadata: this.metadata,
        };
    }

    toIClientUser(): IClientUser {
        return {
            _id: this.spinachUserId,
            email: this.email,
            metadata: this.metadata,
            seriesMetadataList: this.seriesMetadataList,
            preferredName: this.preferredName,
            createdOn: this.metadata.createdOn!,
            zoomUserId: this.zoomUserId,
            lastEditedOn: this.metadata.lastEditedOn,
            lastLoggedOn: this.metadata.lastLoggedOn,
            integrationSettings: this.integrationSettings,
            intercomHash: this.metadata.intercomHash,
            googleId: this.googleId,
            featureToggles: this.featureToggles,
            stripeCustomerId: this.stripeCustomerId,
            microsoftId: this.microsoftId,
            recallZoomAuthCredentialId: this.recallZoomAuthCredentialId,
            hasProFeatures: this.hasProFeatures,
        };
    }
}
