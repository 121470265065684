import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postAnonymousFeatureFlag<T>(
    key: string,
    defaultValue: T,
    anonUUID: string | undefined = undefined,
    suid?: string
): Promise<T> {
    try {
        const response = await postSpinachAPI<{ value: T }>(SpinachAPIPath.AnonymousFeatureToggle, {
            key,
            anonUUID,
            suid,
        });
        if (response?.value) {
            return response.value;
        } else {
            return defaultValue;
        }
    } catch {
        return defaultValue;
    }
}
