import { AccountBox, Create, Group, Person } from '@material-ui/icons';

import { CalendarEvent } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import {
    GlobalModal,
    GlobalModalMetadataType,
    PrimaryButton,
    useGlobalAuthedUser,
    useGlobalModal,
} from '../../../../..';
import { useSelectSummaryAccess } from '../../../../hooks/useSelectSummaryAccess';
import { useToggleEditSummaries } from '../../../../hooks/useToggleEditSummaries';
import { BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { BootstrapTooltip, ProBadge, Row, Spacing } from '../../../common';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';

const UpgradeToProHyperlink = ({ title, from }: { title: string; from: string }) => {
    const navigateToUpgrade = useNavigateToUpgrade();
    return (
        <PrimaryButton
            size={ButtonSize.Small}
            title={title}
            onClick={(e) => {
                e.stopPropagation();
                navigateToUpgrade(`Click Upgrade to Pro (${from})`);
            }}
        />
    );
};

const SummaryLocationMeetingTooltip = ({
    isEditable,
    storedSeries,
}: {
    isEditable: boolean;
    storedSeries: StoredSpinachSeries;
}) => {
    const [user] = useGlobalAuthedUser();

    const { isEmailingIcpOnly, onSelect: onChange } = useSelectSummaryAccess({ storedSeries, from: 'Meeting Tooltip' });
    const toggleSummaryLocation = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!isEditable) {
            return;
        }
        if (!user.hasProFeatures) {
            return;
        }
        onChange(!isEmailingIcpOnly);
    };

    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <>
                    <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                        Summaries will be sent to{' '}
                        {isEmailingIcpOnly && user.hasProFeatures ? (
                            <span style={{ fontWeight: 'bold' }}>the person who invited Spinach</span>
                        ) : (
                            <span style={{ fontWeight: 'bold' }}>all invitees.</span>
                        )}
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 3} />
                    {user.hasProFeatures ? (
                        <>
                            <Row style={{ width: 'fit-content' }}>
                                <ProBadge containerStyle={{ marginLeft: 'unset' }} />
                            </Row>
                        </>
                    ) : (
                        <Row style={{ width: 'fit-content' }}>
                            <UpgradeToProHyperlink title={'Upgrade to Pro'} from="Emailing ICP Only Meeting Tooltip" />
                        </Row>
                    )}
                    <Spacing factor={1 / 5} />
                </>
            }
        >
            {isEmailingIcpOnly ? (
                <Person
                    onClick={toggleSummaryLocation}
                    style={{
                        color: lightTheme.primary.greenLight,
                        cursor: user.hasProFeatures && isEditable ? 'pointer' : 'not-allowed',
                    }}
                />
            ) : (
                <Group
                    onClick={toggleSummaryLocation}
                    style={{
                        color: lightTheme.primary.greenLight,
                        cursor: user.hasProFeatures && isEditable ? 'pointer' : 'not-allowed',
                    }}
                />
            )}
        </BootstrapTooltip>
    );
};

const EditSummaryMeetingTooltip = ({
    isEditable,
    storedSeries,
}: {
    isEditable: boolean;
    storedSeries: StoredSpinachSeries;
}) => {
    const [user] = useGlobalAuthedUser();
    const { currentSeriesEditingFlag, toggleEditSummary } = useToggleEditSummaries({
        storedSeries,
        from: 'Meeting Tooltip',
    });
    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <>
                    <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                        Edit summaries before sending to recipients.
                    </BodyRegularOnboard>

                    <Spacing factor={1 / 3} />
                    {user.hasProFeatures ? (
                        <Row style={{ width: 'fit-content' }}>
                            <ProBadge containerStyle={{ marginLeft: 'unset' }} />
                        </Row>
                    ) : (
                        <Row style={{ width: 'fit-content' }}>
                            <UpgradeToProHyperlink from="Edit Summary Meeting Tooltip" title="Upgrade to Pro" />
                        </Row>
                    )}
                    <Spacing factor={1 / 5} />
                </>
            }
        >
            <Create
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isEditable) {
                        return;
                    }
                    if (!user.hasProFeatures) {
                        return;
                    }
                    toggleEditSummary();
                }}
                style={{
                    color: currentSeriesEditingFlag ? lightTheme.primary.greenLight : 'gray',
                    cursor: isEditable && user.hasProFeatures ? 'pointer' : 'not-allowed',
                }}
            />
        </BootstrapTooltip>
    );
};

const MeetingHostMeetingTooltip = ({
    storedSeries,
    isEditable,
}: {
    storedSeries: StoredSpinachSeries;
    isEditable: boolean;
}) => {
    const [, setGlobalModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();
    const spinachIcp = storedSeries.userMetadataList.find((user) => user._id === storedSeries.icpId);
    if (!spinachIcp) {
        return <></>;
    }
    const spinachIcpName = spinachIcp.preferredName;
    const spinachIcpDisplayName = spinachIcpName ? `${spinachIcpName} (${spinachIcp.email})` : spinachIcp.email;
    const isCurrentUserHost = user.email === spinachIcp.email;
    const hostName = isCurrentUserHost ? 'You' : spinachIcpDisplayName;
    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                    <span style={{ fontWeight: 'bold' }}>{hostName}</span> {isCurrentUserHost ? 'are' : 'is'} the
                    Spinach host {isCurrentUserHost || !user.isClaimHostEnabled ? '' : `(click to claim host)`}
                </BodyRegularOnboard>
            }
        >
            <AccountBox
                style={{
                    width: '24px',
                    height: '24px',
                    fontSize: '11px',
                    cursor:
                        isCurrentUserHost || !user.isClaimHostEnabled
                            ? 'default'
                            : isEditable
                            ? 'pointer'
                            : 'not-allowed',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isEditable) {
                        return;
                    }

                    if (!user.isClaimHostEnabled) {
                        return;
                    }

                    if (isCurrentUserHost) {
                        return;
                    }

                    setGlobalModal(GlobalModal.ClaimHost, {
                        metadata: { seriesId: storedSeries.id, metadataType: GlobalModalMetadataType.ClaimHost },
                    });
                }}
                htmlColor={isCurrentUserHost ? lightTheme.primary.greenLight : 'gray'}
            />
        </BootstrapTooltip>
    );
};

export const MeetingInformationTooltips = ({
    calendarEvent,
    hasEventAlreadyPast,
    storedSeries,
}: {
    calendarEvent: CalendarEvent;
    hasEventAlreadyPast: boolean;
    storedSeries?: StoredSpinachSeries;
}): JSX.Element => {
    if (!storedSeries || !calendarEvent.isScribeOnEvent) {
        return <></>;
    }
    return (
        <>
            <SummaryLocationMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
            <EditSummaryMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
            <MeetingHostMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
        </>
    );
};
