import { useEffect, useState } from 'react';

import { ClientEventType, ScribeMetadata } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { patchSeries, useExperienceTracking } from '../..';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';

export const useToggleEditSummaries = ({ storedSeries, from }: { storedSeries: StoredSpinachSeries; from: string }) => {
    const { updateSeriesInList } = useGlobalStoredSeriesList();
    const track = useExperienceTracking();
    const [currentSeriesEditingFlag, setCurrentSeriesEditingFlag] = useState(!!storedSeries.isEditSummaryEnabled);

    useEffect(() => {
        setCurrentSeriesEditingFlag(storedSeries.isEditSummaryEnabled);
    }, [storedSeries.isEditSummaryEnabled]);

    return {
        currentSeriesEditingFlag,
        toggleEditSummary: async () => {
            try {
                setCurrentSeriesEditingFlag(!currentSeriesEditingFlag);
                const storedSeriesJSON = storedSeries.toJSON();
                /** @NOTE optimistic update */
                updateSeriesInList({
                    ...storedSeriesJSON,
                    metadata: {
                        ...storedSeriesJSON.metadata,
                        scribeMetadata: {
                            ...(storedSeriesJSON.metadata?.scribeMetadata as ScribeMetadata),
                            isHostEditing: !currentSeriesEditingFlag,
                        },
                    },
                });
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: `Toggle Enable Edit Summary (${from})`,
                    ToggleTo: currentSeriesEditingFlag ? 'Off' : 'On',
                });
                const updatedSeries = await patchSeries(storedSeries.id, {
                    metadata: {
                        scribeMetadata: {
                            isHostEditing: !currentSeriesEditingFlag,
                        },
                    },
                });

                if (updatedSeries) {
                    updateSeriesInList(updatedSeries);
                }
            } catch (e) {
                setCurrentSeriesEditingFlag(currentSeriesEditingFlag);
                updateSeriesInList(storedSeries.toJSON());
            }
        },
    };
};
