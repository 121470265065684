import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CircularProgress,
    Tooltip,
    makeStyles,
    withStyles,
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import { CallMade, ErrorOutline, ExpandMore, FileCopyOutlined, Info, InfoOutlined, Warning } from '@material-ui/icons';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import { CalendarEvent, ClientEventType, FeatureToggle, SpinachAPIPath } from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    TimeUtils,
    getMasterEventId,
    getSharedLink,
    getUniques,
    haveSummarySectionsBeenEdited,
    isMeetingTypeEnabledForEditing,
} from '@spinach-shared/utils';

import { patchSeries, patchUser } from '../../../../apis';
import { getUserMetadata } from '../../../../apis/getUserMetadata';
import VideoExampleImage from '../../../../assets/video-example.png';
import { GlobalModal } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    useIntegrationDetection,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useScribeEmail } from '../../../../hooks/useScribe';
import { useToggleEditSummaries } from '../../../../hooks/useToggleEditSummaries';
import {
    BodyRegularOnboard,
    BodyRegularOnboardWithTextWrap,
    ButtonSize,
    lightTheme,
    responsiveness,
} from '../../../../styles';
import { SetValue } from '../../../../types';
import { URLUtil, copyTextToClipboard, isWebPlatform, withContentMasking } from '../../../../utils';
import { inMemoryCache } from '../../../../utils/cache';
import { Anchor, BootstrapTooltip, ClientPath, Column, Row } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { PrimaryButton } from '../../../stand-up';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import './CombinedMeetingSection.css';
import { CustomFeatureSettings } from './CustomFeatureSettings';
import { CustomSummaryDetails } from './CustomSummaryDetails';
import { LanguageSettingsDetails } from './LanguageSettingsDetails';
import { MeetingCommunicationDetails } from './MeetingCommunicationDetails';
import { MeetingInformationTooltips } from './MeetingInformationTooltips';

const StyledAccordionSummary = withStyles<'root' | 'content', {}, {}>({
    root: {
        padding: '0px',
    },
    content: {},
})(AccordionSummary);

const StyledAccordionDetails = withStyles<'root', {}, {}>({
    root: {
        padding: '0px',
        flexDirection: 'column',
    },
})(AccordionDetails);

export const MeetingCheck = styled.div<{ isChecked: boolean; isInteractive: boolean; isHovered?: boolean }>`
    width: 30px;
    height: 30px;
    background: ${(props) =>
        props.isChecked || props.isHovered ? props.theme.secondary.green : props.theme.neutrals.gray};
    justify-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
    opacity: ${(props) => (props.isHovered && !props.isChecked ? 0.2 : 1)};
    cursor: ${(props) => (!props.isInteractive ? 'not-allowed' : 'pointer')};
`;

function EditSummaryDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    const isProAccount = useProFeatures();
    const [user] = useGlobalAuthedUser();

    const isMeetingTypeEnabledForEdit = isMeetingTypeEnabledForEditing(storedSeries.scribeMetadata?.meetingType);
    const areSectionsEdited = haveSummarySectionsBeenEdited(
        storedSeries.scribeMetadata?.meetingType,
        storedSeries.scribeMetadata?.enabledSummarySections
    );

    const isEditSummaryEnabled =
        isMeetingTypeEnabledForEdit && (!areSectionsEdited || user.isEnabledForSupportEditAfterCustomizingSections);
    const { currentSeriesEditingFlag, toggleEditSummary } = useToggleEditSummaries({
        storedSeries,
        from: 'Meeting Settings',
    });

    const navigateToUpgrade = useNavigateToUpgrade();

    let tooltipTitle = '';
    if (!isMeetingTypeEnabledForEdit) {
        tooltipTitle = 'This meeting type is not yet supported for editing.';
    } else if (areSectionsEdited && !user.isEnabledForSupportEditAfterCustomizingSections) {
        tooltipTitle = 'This meeting type has been customized and is not supported for editing.';
    }

    return (
        <>
            <SettingsSubTitle>{'Edit Summary'}</SettingsSubTitle>
            <SettingsContent>
                <Row style={{ flexWrap: 'wrap' }}>
                    <BodyRegularOnboard>
                        <>
                            {isProAccount ? (
                                <BootstrapTooltip title={tooltipTitle} placement="top">
                                    <Box height="24px" display="flex" flexDirection="row" mt="5px">
                                        <SpinachSwitch
                                            checked={isEditSummaryEnabled ? currentSeriesEditingFlag : false}
                                            disabled={!isEditSummaryEnabled}
                                            onChange={toggleEditSummary}
                                        />
                                        <Box fontSize="16px" ml="10px" mt="4px">
                                            {'Edit summaries before they publish'}
                                        </Box>
                                    </Box>
                                </BootstrapTooltip>
                            ) : (
                                <Column style={{ marginLeft: '15px' }}>
                                    <BodyRegularOnboard>
                                        Summaries are immediately sent out.{' '}
                                        <Anchor
                                            onClick={() => {
                                                navigateToUpgrade('Click Upgrade Edit Summary (Settings Section)');
                                            }}
                                        >
                                            Upgrade to Pro
                                        </Anchor>{' '}
                                        to edit beforehand.
                                    </BodyRegularOnboard>
                                </Column>
                            )}
                        </>
                    </BodyRegularOnboard>
                </Row>
            </SettingsContent>
        </>
    );
}

const MainMeetingSettings = styled.div`
    font-size: 22px;
    grid-column: 2;
    min-height: 40px;
    padding-top: 7px;
    font-weight: bold;

    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
        margin-left: 10px;
        grid-column: 1 / -1;
        min-height: 0px;
    }
`;

export const SettingsSubTitle = styled.div`
    grid-column: 2;
    min-height: 40px;
    padding-top: 7px;
    font-weight: bold;

    @media ${responsiveness.thinnerThanSM} {
        margin-left: 10px;
        grid-column: 1 / -1;
        min-height: 0px;
    }
`;

export const SettingsContent = styled.div`
    grid-column: 3 / -1;
    margin-bottom: 8px;

    @media ${responsiveness.thinnerThanSM} {
        margin-left: 10px;
        grid-column: 1 / -1;
        min-height: 0px;
        margin-bottom: 20px;
        margin-right: 10px;
    }
`;

export function MeetingConfigurationDetails({
    storedSeries,
    launchInMeeting,
}: {
    storedSeries: StoredSpinachSeries;
    launchInMeeting: (openPath?: 'schedule' | 'customize', extraAnalyticsSource?: string) => void;
}) {
    const [user] = useGlobalAuthedUser();
    const isZoomBannerEnabled = user.featureToggles[FeatureToggle.MissingZoomAuthBanner];
    const isZoomMeeting = storedSeries.metadata.scribeMetadata?.meetingPlatform === 'zoom';
    const userZoomIntegrationSetup = !!user.recallZoomAuthCredentialId;
    const accountZoomIntegrationSetup = !!user.metadata.accountLevelRecallZoomAuthCredentialId;

    const organizerUserId = storedSeries.metadata.scribeMetadata?.organizerUserId;
    const isHostedBySelf = organizerUserId === user.spinachUserId;

    const [seriesIcpHasZoomIntegration, setSeriesIcpHasZoomIntegration] = React.useState<boolean>();
    React.useEffect(() => {
        if (!isHostedBySelf && !accountZoomIntegrationSetup && organizerUserId) {
            const fetchZoomIntegration = async () => {
                const icpMetadata = await inMemoryCache(
                    'isZoomIntegrationSetup',
                    organizerUserId,
                    1000 * 60,
                    async () => await getUserMetadata(organizerUserId)
                );
                if (!icpMetadata.isExternalUser) {
                    setSeriesIcpHasZoomIntegration(icpMetadata.isZoomIntegrationSetup);
                }
            };
            fetchZoomIntegration();
        }
    }, [accountZoomIntegrationSetup, isHostedBySelf, organizerUserId]);

    // undefined means we haven't fetched yet,\ we don't want to show the banner until we know. it also coveres for external domain users
    const hostHasZoomIntegration = isHostedBySelf
        ? userZoomIntegrationSetup
        : seriesIcpHasZoomIntegration === true || seriesIcpHasZoomIntegration === undefined;
    const zoomIntegrated = accountZoomIntegrationSetup || hostHasZoomIntegration;
    const showZoomAuthBanner = isZoomMeeting && !zoomIntegrated && isZoomBannerEnabled;

    const startDetection = useIntegrationDetection(
        undefined,
        (original, newUser) => original.recallZoomAuthCredentialId !== newUser.recallZoomAuthCredentialId
    );
    const track = useExperienceTracking();

    const isInMeetingAvailable =
        user.isEnabledForCombinedSummaries &&
        storedSeries.isEligibleForCombinedSummary(user.isGenericCombinedSummaryEnabled);

    return (
        <Box
            display="grid"
            gridColumnGap="10px"
            gridTemplateColumns="24px minmax(15vw, auto) 1fr 100px"
            gridColumn="1 / -1"
            width="100%"
        >
            {showZoomAuthBanner ? (
                <Box
                    gridColumn="2 / -1"
                    display="flex"
                    flexDirection="column"
                    style={{
                        backgroundColor: '#FFF1E9',
                        borderRadius: '4px',
                        justifyContent: 'center',
                        padding: '10px 8px 10px 2px',
                        marginBottom: '20px',
                    }}
                >
                    <Box display="flex" flexDirection="row">
                        <Info style={{ color: '#F69561', padding: '0px 8px 0px 3px' }} />
                        <BodyRegularOnboard style={{ fontWeight: 400 }}>
                            <b>Make Sure Your Meetings are Captured!</b>
                            <div>
                                {isHostedBySelf
                                    ? "Connect to your zoom account to ensure all spinach meetings are recorded even when you aren't on the call"
                                    : 'Please ask the meeting host to connect their Zoom account to Spinach to ensure Spinach can record meetings when they are late or not on the call.'}
                            </div>
                        </BodyRegularOnboard>
                    </Box>

                    {isHostedBySelf ? (
                        <Box alignSelf="self-end" mr="10px" mb="5px">
                            <PrimaryButton
                                title="Authenticate with Zoom"
                                onClick={() => {
                                    URLUtil.openURL(
                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.RecallZoomAppAuth}`
                                    );
                                    startDetection();
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Integrate zoom from banner',
                                    });
                                }}
                            />
                        </Box>
                    ) : null}
                </Box>
            ) : undefined}

            <MainMeetingSettings>{'Meeting Settings'}</MainMeetingSettings>
            <CustomSummaryDetails storedSeries={storedSeries} />
            <LanguageSettingsDetails storedSeries={storedSeries} />
            <EditSummaryDetails storedSeries={storedSeries} />
            <MeetingCommunicationDetails storedSeries={storedSeries} />

            {isInMeetingAvailable ? (
                <>
                    <Box
                        height="1px"
                        mb="35px"
                        style={{ background: lightTheme.neutrals.gray }}
                        mt="15px"
                        gridColumn="2 / -2"
                    />
                    <InMeetingDetails storedSeries={storedSeries} launchInMeeting={launchInMeeting} />
                </>
            ) : (
                <></>
            )}
        </Box>
    );
}

const InMeetingDetails = ({
    storedSeries,
    launchInMeeting,
}: {
    storedSeries: StoredSpinachSeries;
    launchInMeeting: (openPath?: 'customize' | 'schedule', extraAnalyticsSource?: string) => void;
}) => {
    const [user, setUser] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = React.useState(false);
    const tooltipClasses = useTooltipStyles();
    const { setToastText } = useGlobalAiDashboard();
    const { routeToDemoExperience } = useGlobalRouting();
    const track = useExperienceTracking();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();
    const encodedMeetingId = encodeURIComponent(storedSeries.slug);
    const shareLink = getSharedLink(encodedMeetingId);

    const toggleState = async () => {
        const beforeChange = !!storedSeries.metadata.inMeetingEnabled;
        let success: boolean = false;
        try {
            setIsLoading(true);
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Toggle In Meeting (Meeting Settings)',
                ToggleTo: beforeChange ? 'Off' : 'On',
            });
            const updatedSeries = await patchSeries(storedSeries.id, {
                metadata: {
                    inMeetingEnabled: !beforeChange,
                },
            });

            if (updatedSeries) {
                const instance = new StoredSpinachSeries(updatedSeries);
                const newList = storedSeriesListState.storedSeriesList.map((series) => {
                    if (series.id === instance.id) {
                        return instance;
                    } else {
                        return series;
                    }
                });
                setStoredSeriesList(newList);
            }
            success = true;
        } catch (error) {
            setToastText('Error updating in meeting setting');
        } finally {
            setIsLoading(false);
        }

        // update user flag that feature was used
        if (!user.metadata.hasAddedFacilitation && beforeChange === false && success === true) {
            const { user: updatedUser } = await patchUser({
                metadata: { hasAddedFacilitation: true },
            });
            if (updatedUser) {
                setUser(updatedUser);
            }
        }
    };

    const isInMeetingAvailable = storedSeries.isEligibleForCombinedSummary(user.isGenericCombinedSummaryEnabled);

    return (
        <>
            <SettingsSubTitle>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box fontWeight="bold" fontSize="18px">
                        {'In-Meeting Agenda'}
                    </Box>
                    <Tooltip
                        classes={tooltipClasses}
                        arrow
                        interactive
                        title={
                            <Box
                                color={lightTheme.tooltip.text}
                                fontSize="14px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                paddingTop="7px"
                            >
                                <img src={VideoExampleImage} width="150px" />
                                <Box px="10px" paddingTop="10px" paddingBottom="5px" textAlign="center">
                                    {'Spinach keeps your daily '}
                                    <b>{'standup'}</b>
                                    {' on track with a visual agenda. '}
                                    <Box>
                                        <Anchor
                                            style={{ color: lightTheme.primary.greenLight }}
                                            onClick={() => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Standup App Demo From Tooltip',
                                                });
                                                const success = URLUtil.openURL(
                                                    `${window.location.origin}${ClientPath.Demo}`
                                                );
                                                if (!success) {
                                                    // navigate page if cannot open popout
                                                    routeToDemoExperience();
                                                }
                                            }}
                                        >
                                            {'See how it works'}
                                        </Anchor>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        placement="top"
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <Box fontSize="12px" my="auto" ml="5px" mr="40px" color={lightTheme.primary.green}>
                            {'(What is this?)'}
                        </Box>
                    </Tooltip>
                </Box>
            </SettingsSubTitle>
            <SettingsContent>
                <Box height="24px" display="flex" flexDirection="row" mt="5px">
                    {isLoading ? (
                        <CircularProgress
                            size={'25px'}
                            style={{ color: lightTheme.secondary.green, marginLeft: '10px' }}
                        />
                    ) : (
                        <SpinachSwitch
                            disabled={!isInMeetingAvailable}
                            checked={storedSeries.metadata.inMeetingEnabled}
                            onChange={toggleState}
                            style={{ cursor: isInMeetingAvailable ? 'pointer' : 'not-allowed' }}
                        />
                    )}
                    <Box fontSize="16px" ml="10px" mt="4px">
                        {'Use Spinach Agenda'}
                    </Box>
                </Box>
            </SettingsContent>

            {storedSeries.metadata.inMeetingEnabled ? (
                <>
                    <SettingsSubTitle>{'Open Agenda'}</SettingsSubTitle>
                    <SettingsContent style={{ paddingBottom: '20px', marginTop: '5px' }}>
                        <PrimaryButton
                            size={ButtonSize.Mini}
                            containerStyles={{ paddingLeft: '20px', paddingRight: '20px' }}
                            title="Launch Agenda"
                            disabled={!storedSeries.metadata.inMeetingEnabled}
                            onClick={() => launchInMeeting()}
                        />
                        {user.featureToggles[FeatureToggle.FakeDeleteMeetingAfterAgendaCheckbox] ? (
                            <Box display="flex">
                                <Checkbox
                                    title="Delete meeting once agenda is complete"
                                    style={{ marginLeft: '0px' }}
                                />
                                <Box mt="12px">Delete meeting once agenda is complete</Box>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </SettingsContent>

                    <SettingsSubTitle>{'Reminders and Async'}</SettingsSubTitle>
                    <SettingsContent style={{ paddingBottom: '20px', marginTop: '5px' }}>
                        <DisablableLink
                            onClick={() => launchInMeeting('schedule', 'Reminders and Async')}
                            text={'Manage Settings'}
                            disable={!storedSeries.metadata.inMeetingEnabled}
                        />
                    </SettingsContent>

                    <SettingsSubTitle>{'Customize Agenda'}</SettingsSubTitle>
                    <SettingsContent style={{ paddingBottom: '20px', marginTop: '5px' }}>
                        <DisablableLink
                            onClick={() => launchInMeeting('customize', 'Customize')}
                            text={'Customize'}
                            disable={!storedSeries.metadata.inMeetingEnabled}
                        />
                    </SettingsContent>

                    <SettingsSubTitle>{'Share link to Agenda'}</SettingsSubTitle>
                    <SettingsContent style={{ paddingBottom: '20px', marginTop: '5px' }}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            onClick={
                                storedSeries.metadata.inMeetingEnabled
                                    ? () => {
                                          track(ClientEventType.AIDashboardClick, {
                                              ClickedOn: 'Copy agenda link',
                                          });
                                          copyTextToClipboard(shareLink);
                                          setToastText('Copied to clipboard');
                                      }
                                    : undefined
                            }
                            style={{ cursor: storedSeries.metadata.inMeetingEnabled ? 'pointer' : 'not-allowed' }}
                        >
                            <Box
                                color={
                                    storedSeries.metadata.inMeetingEnabled
                                        ? lightTheme.primary.green
                                        : lightTheme.neutrals.grayDark
                                }
                                mr="10px"
                            >
                                {shareLink}
                            </Box>
                            <FileCopyOutlined
                                style={{
                                    color: storedSeries.metadata.inMeetingEnabled
                                        ? lightTheme.primary.green
                                        : lightTheme.neutrals.grayDark,
                                }}
                            />
                        </Box>
                        <Box mt="10px">{'Share this agenda with participants before the meeting'}</Box>
                    </SettingsContent>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const DisablableLink = ({ onClick, text, disable }: { onClick: () => void; text: string; disable: boolean }) => (
    <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={disable ? undefined : onClick}
        style={{ cursor: disable ? 'not-allowed' : 'pointer' }}
    >
        <Box color={disable ? lightTheme.neutrals.grayDark : lightTheme.primary.green}>{text}</Box>
        <CallMade style={{ color: disable ? lightTheme.neutrals.grayDark : lightTheme.primary.green }} />
    </Box>
);

const MeetingSwitchComponent = ({
    isUpdatingEvent,
    isChecked,
    meetingUrl,
    isBotInvitedWithoutLink,
    onChange,
    isError,
    hasEventAlreadyPast,
}: {
    isError?: boolean;
    isUpdatingEvent: boolean;
    isBotInvitedWithoutLink?: boolean;
    onChange: () => void;
    isChecked: boolean;
    hasEventAlreadyPast: boolean;
    meetingUrl?: string | null;
}) => {
    const [user] = useGlobalAuthedUser();

    if (isError) {
        return (
            <ErrorOutline
                style={{
                    color: lightTheme.primary.orangeDark,
                    height: '38px',
                    width: '24px',
                    paddingLeft: '24px',
                    paddingRight: '13px',
                }}
            />
        );
    }

    if (hasEventAlreadyPast) {
        return (
            <BootstrapTooltip title="This event has already passed">
                <InfoOutlined
                    style={{ color: lightTheme.primary.greenLight }}
                    htmlColor={lightTheme.primary.greenLight}
                />
            </BootstrapTooltip>
        );
    }

    if (!!user.metadata.isUsingRecallCalendarV2 && !meetingUrl) {
        return (
            <BootstrapTooltip title="Spinach requires a video conference link.">
                <InfoOutlined
                    style={{ color: lightTheme.primary.greenLight }}
                    htmlColor={lightTheme.primary.greenLight}
                />
            </BootstrapTooltip>
        );
    }

    if (isBotInvitedWithoutLink) {
        return (
            <BootstrapTooltip title="Add a video link to this calendar event so Spinach can join.">
                <Warning htmlColor={lightTheme.primary.orangeLight} style={{ zIndex: 100 }} />
            </BootstrapTooltip>
        );
    }

    return isUpdatingEvent ? (
        <CircularProgress size={'24px'} style={{ color: lightTheme.secondary.green }} />
    ) : (
        <SpinachSwitch
            disabled={hasEventAlreadyPast}
            checked={isChecked}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
        />
    );
};

const NoMeetingUrlFoundError = () => (
    <Box
        display="flex"
        flex="1"
        m="20px"
        style={{
            margin: '20px',
            backgroundColor: '#FFF1E9',
            borderRadius: '4px',
            justifyContent: 'center',
            padding: '10px 8px 10px 2px',
        }}
    >
        <InfoOutlined style={{ color: '#F69561', padding: '0px 8px 0px 3px' }} />
        <Column>
            <BodyRegularOnboard style={{ fontWeight: 400 }}>
                {'Spinach requires a video conference link.'}
            </BodyRegularOnboard>
        </Column>
    </Box>
);

const InviteSpinachDetails = () => (
    <Box
        display="flex"
        flex="1"
        m="20px"
        style={{
            margin: '20px',
            backgroundColor: lightTheme.tertiary.greenLight,
            borderRadius: '4px',
            justifyContent: 'center',
            padding: '10px 8px 10px 2px',
        }}
    >
        <Info style={{ color: lightTheme.status.positive, padding: '0px 8px 0px 3px' }} />
        <Column>
            <BodyRegularOnboard style={{ fontWeight: 400 }}>
                {'To add Spinach to this meeting, toggle on "Invite Spinach"'}
            </BodyRegularOnboard>
        </Column>
    </Box>
);

const SeriesStillSyncing = ({
    calendarEvent,
    eventICalUid,
    isOpen,
}: {
    calendarEvent: CalendarEvent;
    eventICalUid: string;
    isOpen: boolean;
}) => {
    const track = useExperienceTracking();
    const {
        state: { reportedEventIds },
        setState,
    } = useGlobalAiDashboard();

    const masterEventId = getMasterEventId(calendarEvent);

    useEffect(() => {
        if (isOpen && !reportedEventIds.includes(masterEventId)) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Expanded Meeting Error',
                MeetingErrorKind: 'Series Still Syncing',
                masterEventId,
                ICalUid: eventICalUid,
                EventId: calendarEvent.id,
                Title: calendarEvent.summary,
            });
            setState((prev) => ({
                ...prev,
                reportedEventIds: getUniques([...prev.reportedEventIds, masterEventId]),
            }));
        }
    }, [
        calendarEvent.id,
        calendarEvent.summary,
        eventICalUid,
        isOpen,
        masterEventId,
        reportedEventIds,
        setState,
        track,
    ]);

    return (
        <Row
            style={{
                backgroundColor: lightTheme.neutrals.grayLight,
                borderRadius: '4px',
                justifyContent: 'center',
                padding: '10px 8px 10px 2px',
            }}
        >
            <Column>
                <BodyRegularOnboard style={{ fontWeight: 400, marginLeft: '10px' }}>
                    {"We're awaiting our invitation. This should only take a few minutes."}
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

const SeriesCreationError = ({
    calendarEvent,
    eventICalUid,
    isOpen,
}: {
    calendarEvent: CalendarEvent;
    eventICalUid: string;
    isOpen: boolean;
}) => {
    const track = useExperienceTracking();
    const {
        state: { reportedEventIds },
        setState,
    } = useGlobalAiDashboard();

    const masterEventId = getMasterEventId(calendarEvent);

    useEffect(() => {
        if (isOpen && !reportedEventIds.includes(masterEventId)) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Expanded Meeting Error',
                MeetingErrorKind: 'Series Creation Error',
                masterEventId,
                ICalUid: eventICalUid,
                EventId: calendarEvent.id,
                Title: calendarEvent.summary,
            });
            setState((prev) => ({
                ...prev,
                reportedEventIds: getUniques([...prev.reportedEventIds, masterEventId]),
            }));
        }
    }, [
        calendarEvent.id,
        calendarEvent.summary,
        eventICalUid,
        isOpen,
        masterEventId,
        reportedEventIds,
        setState,
        track,
    ]);

    useEffect(() => {
        track(ClientEventType.AIDashboardActivity, {
            Action: 'Opened series with creation error',
            CalendarEventTitle: calendarEvent.summary,
            EventICalUid: eventICalUid,
        });
    }, []);

    return (
        <Row
            style={{
                backgroundColor: lightTheme.secondary.orangeLight,
                borderRadius: '4px',
                justifyContent: 'center',
                padding: '10px 8px 10px 2px',
            }}
        >
            <Warning style={{ color: lightTheme.secondary.orangeDark, padding: '0px 8px 0px 3px' }} />
            <Column>
                <BodyRegularOnboard style={{ fontWeight: 400 }}>
                    {"Something's missing, here. If the problem persists, please reach out through our "}
                    <Anchor
                        style={{ color: lightTheme.primary.greenDark, textDecoration: 'none' }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(INTERCOM_SUPPORT_URL);
                        }}
                    >
                        {'Help Center'}
                    </Anchor>
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

const MeetingErrorDetails = ({ isOpen, calendarEvent }: { isOpen: boolean; calendarEvent: CalendarEvent }) => {
    const scribeEmail = useScribeEmail();
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const {
        state: { reportedEventIds },
        setState,
    } = useGlobalAiDashboard();

    const masterEventId = getMasterEventId(calendarEvent);

    useEffect(() => {
        if (isOpen && !reportedEventIds.includes(masterEventId)) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Expanded Meeting Error',
                MeetingErrorKind: 'General Toggle Meeting Error',
                masterEventId,
                ICalUid: calendarEvent.iCalUID,
                EventId: calendarEvent.id,
                Title: calendarEvent.summary,
            });
            setState((prev) => ({
                ...prev,
                reportedEventIds: getUniques([...prev.reportedEventIds, masterEventId]),
            }));
        }
    }, [
        calendarEvent.iCalUID,
        calendarEvent.id,
        calendarEvent.summary,
        isOpen,
        masterEventId,
        reportedEventIds,
        setState,
        track,
    ]);

    const linkToOpen = user.isAuthedForMicrosoftCalendar
        ? 'https://intercom.help/spinach_io/en/articles/8150039-spinach-io-on-ms-teams'
        : undefined;

    return (
        <Row
            style={{
                backgroundColor: '#FFA87729',
                borderRadius: '4px',
                padding: '10px 8px 10px 2px',
            }}
        >
            <ErrorOutline style={{ color: lightTheme.primary.orangeDark, padding: '0px 8px 3px 3px' }} />
            <Column>
                <BodyRegularOnboard style={{ fontWeight: 400 }}>
                    We were unable to add Spinach to this meeting. You can reach out to the meeting owner and ask them
                    to add <b>{scribeEmail}</b> to the calendar invite. Otherwise, contact support{' '}
                    <Anchor
                        id={linkToOpen ? undefined : 'spinach_intercom'}
                        // dynamic id if we have no article
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Support for Failed ${
                                    linkToOpen ? 'Microsoft Calendar Add' : 'Google Calendar Add'
                                }`,
                            });
                            if (linkToOpen) {
                                URLUtil.openURL(linkToOpen);
                            }
                        }}
                    >
                        here
                    </Anchor>
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

type CombinedMeetingDetailsProps = {
    isOpen: boolean;
    isOnboardingFlow: boolean;
    isSeriesLoading: boolean;
    storedSeries?: StoredSpinachSeries;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    calendarEvent: CalendarEvent;
    setIsOpen: () => void;
    setFailedEvents: SetValue<string[]>;
    onChange: () => void;
    updatingEvents: string[];
    isError?: boolean;
};

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: 'auto',
        background: lightTheme.tooltip.background,
    },
    arrow: {
        color: lightTheme.tooltip.background,
    },
    tooltipPlacementTop: {
        marginBottom: 10,
    },
}));

export const useOpenInMeetingApp = () => {
    const { routeToSeriesExperience } = useGlobalRouting();
    const track = useExperienceTracking();

    const [, setGlobalModal] = useGlobalModal();

    return React.useCallback(
        (series: StoredSpinachSeries, analyticsSource: string, openPath?: 'schedule' | 'customize') => {
            if (series?.metadata.inMeetingEnabled || !series.metadata.scribeMetadata) {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: openPath
                        ? `Launch Facilitation App (${analyticsSource})`
                        : `Launch Facilitation App for settings (${analyticsSource})`,
                });
                let openedWindow: boolean = false;

                const target = `${window.location.origin}/meeting/${encodeURIComponent(series.id)}${
                    openPath ? `/${openPath}` : ''
                }`;

                if (isWebPlatform()) {
                    try {
                        openedWindow = !!URLUtil.openURLPopUp(`${target}?window=popout`);
                    } catch (error) {}
                }

                if (!openedWindow) {
                    routeToSeriesExperience(series.id, { replace: true });
                    if (openPath === 'schedule') {
                        setGlobalModal(GlobalModal.Schedule);
                    } else if (openPath === 'customize') {
                        setGlobalModal(GlobalModal.SeriesSettings);
                    }
                }
            }
        },
        [routeToSeriesExperience, setGlobalModal, track]
    );
};

export function CombinedMeetingDetails({
    isOpen,
    setIsOpen,
    isOnboardingFlow,
    isSeriesLoading,
    onboardingEventsToAddScribeTo,
    storedSeries,
    calendarEvent,
    updatingEvents,
    isError,
    onChange,
}: CombinedMeetingDetailsProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const isUpdatingEvent = Boolean(updatingEvents.includes(getMasterEventId(calendarEvent)));
    const { isDesktopView } = useWindowSize();

    const isScribeEnabled =
        isScribeOnEvent(calendarEvent) || (isOnboardingFlow && onboardingEventsToAddScribeTo.includes(calendarEvent));

    const newlyCreated = React.useMemo(
        () => (calendarEvent.created && moment(calendarEvent.created).isAfter(moment().subtract(4, 'minute'))) || false,
        [calendarEvent.created]
    );

    const launchInMeeting = useOpenInMeetingApp();

    const hasEventAlreadyPast = Boolean(
        calendarEvent.end?.dateTime &&
            moment(calendarEvent.end.dateTime)
                .tz(calendarEvent.end.timeZone ?? TimeUtils.getTimezoneRegion())
                .isBefore(moment().tz(TimeUtils.getTimezoneRegion()))
    );
    /**
     * @NOTE disable if the event is in the past, and there is no stored series, and the event is not recurring
     * If the event is recurring, it's ok to make the accordion not disabled, since any changes will apply
     * to future events.
     */
    const meetingTitle = calendarEvent.summary ?? storedSeries?.meetingTitle;

    return (
        <Box>
            <Accordion
                expanded={isOpen}
                onChange={() => {
                    if (hasEventAlreadyPast) {
                        return;
                    }

                    setIsOpen();
                }}
                style={{
                    boxShadow: 'none',
                    border: 'solid 1px #E0E0E0',
                }}
            >
                <StyledAccordionSummary
                    style={{
                        cursor: hasEventAlreadyPast ? 'initial' : undefined,
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flex: 1,
                            minHeight: '20px',
                        }}
                    >
                        {hasEventAlreadyPast ? (
                            <span style={{ width: '24px' }}></span>
                        ) : (
                            <ExpandMore
                                style={{
                                    width: '24px',
                                    gridColumn: '1',
                                    transition: '100ms',
                                    transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)',
                                }}
                            />
                        )}
                        <BodyRegularOnboardWithTextWrap
                            style={{
                                width: isDesktopView ? '180px' : '60px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                display: 'flex',
                                flexDirection: isDesktopView ? 'row' : 'column',
                            }}
                        >
                            <Box
                                style={{
                                    flex: 1,
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    display: 'webkit-box',
                                    WebkitLineClamp: 2,
                                    whiteSpace: 'normal',
                                    color: hasEventAlreadyPast ? 'gray' : 'inherit',
                                }}
                            >{`${moment(calendarEvent.start?.dateTime).format('ddd MM/DD')}${
                                isDesktopView ? ' - ' : ' '
                            }${moment(calendarEvent.start?.dateTime).format('h:mmA')}`}</Box>
                        </BodyRegularOnboardWithTextWrap>
                        <BodyRegularOnboardWithTextWrap
                            style={{
                                flex: 1,
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                display: 'webkit-box',
                                WebkitLineClamp: 2,
                                whiteSpace: 'normal',
                                color: hasEventAlreadyPast ? 'gray' : 'inherit',
                            }}
                            {...withContentMasking()}
                        >
                            {meetingTitle}
                        </BodyRegularOnboardWithTextWrap>
                        <Box display="flex" alignItems="center" justifyContent="end" style={{ paddingRight: '10px' }}>
                            {user.isMeetingInformationTooltipsEnabled ? (
                                <MeetingInformationTooltips
                                    hasEventAlreadyPast={hasEventAlreadyPast}
                                    storedSeries={storedSeries}
                                    calendarEvent={calendarEvent}
                                />
                            ) : (
                                <></>
                            )}
                            <MeetingSwitchComponent
                                isError={isError}
                                isBotInvitedWithoutLink={storedSeries?.isBotInvitedWithoutLink}
                                meetingUrl={calendarEvent.meetingUrl}
                                isChecked={isScribeEnabled}
                                isUpdatingEvent={isUpdatingEvent}
                                hasEventAlreadyPast={hasEventAlreadyPast}
                                onChange={onChange}
                            />
                        </Box>
                    </Box>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    {storedSeries ? <CustomFeatureSettings storedSeries={storedSeries} /> : <></>}
                    {isError ? (
                        <MeetingErrorDetails isOpen={isOpen} calendarEvent={calendarEvent} />
                    ) : storedSeries && isScribeOnEvent(calendarEvent) ? (
                        <MeetingConfigurationDetails
                            storedSeries={storedSeries}
                            launchInMeeting={(openSettings, extraAnalyticsSource) =>
                                launchInMeeting(
                                    storedSeries,
                                    `Meeting Settings ${extraAnalyticsSource ?? ''}`,
                                    openSettings
                                )
                            }
                        />
                    ) : (
                        <SettingsPlaceholder
                            isScribeOnEvent={isScribeEnabled}
                            newlyCreated={newlyCreated}
                            meetingUrl={calendarEvent.meetingUrl}
                            loading={isUpdatingEvent || isSeriesLoading}
                            eventICalUid={calendarEvent.iCalUID ?? ''}
                            calendarEvent={calendarEvent}
                            isOpen={isOpen}
                        />
                    )}
                </StyledAccordionDetails>
            </Accordion>
        </Box>
    );
}

const SettingsPlaceholder = ({
    isScribeOnEvent,
    newlyCreated,
    loading,
    meetingUrl,
    calendarEvent,
    eventICalUid,
    isOpen,
}: {
    isScribeOnEvent: boolean;
    newlyCreated: boolean;
    loading: boolean;
    meetingUrl?: string | null;
    calendarEvent: CalendarEvent;
    eventICalUid: string;
    isOpen: boolean;
}) => {
    const [user] = useGlobalAuthedUser();
    if (loading) {
        return <></>; // there is another loading indicator for that
    }
    if (isScribeOnEvent) {
        if (newlyCreated) {
            return <SeriesStillSyncing calendarEvent={calendarEvent} eventICalUid={eventICalUid} isOpen={isOpen} />;
        }
        return <SeriesCreationError calendarEvent={calendarEvent} eventICalUid={eventICalUid} isOpen={isOpen} />;
    }
    if (!meetingUrl && user.isUsingRecallV2) {
        return <NoMeetingUrlFoundError />;
    }
    return <InviteSpinachDetails />;
};
