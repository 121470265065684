import { Box, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SpinachAIVideoImage } from '../../../../assets/spinach-video-display.svg';
import { ReactComponent as VideoCam } from '../../../../assets/videocam.svg';
import { GlobalModal } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalModal,
    useIsCustomBrandingEnabled,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import {
    useGlobalBrandedImageUri,
    useGlobalCombinedSpinachVideoBackgroundImageUri,
    useGlobalSpinachVideoBackgroundImageUri,
} from '../../../../hooks/useGlobalBrandedImage';
import { BodyRegularOnboard, BodySmallOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { Anchor, Column, Hairline, ProBadge, Row, Spacing, UpgradeLockIcon } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { Label } from './common';

type ImageProps = {
    preview: boolean;
    scale: number;
};

const grow = ({ scale }: ImageProps) =>
    keyframes` 
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(${scale});
        }
`;

const shrink = ({ scale }: ImageProps) =>
    keyframes` 
        0% {
            transform: scale(${scale});
        }
        100% {
            transform: scale(1);
        }
`;

const fade = () =>
    keyframes` 
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
`;

const growAnimation = (props: ImageProps) => css`
    animation: ${grow(props)};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const shrinkAnimation = (props: ImageProps) => css`
    animation: ${shrink(props)};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const fadeAnimation = () => css`
    animation: ${fade()};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const ImagePreview = styled(Box)<{ width: number; height: number; startClosing: boolean }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 1;
    ${(props) => (props.startClosing ? fadeAnimation() : {})}
`;

const PreviewBox = styled.div`
    position: absolute;
    width: 142px;
    height: 80px;
    background-color: #ffffffcc;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
`;

const ImageSelector = ({
    setPreview,
    setStartClosing,
}: {
    setPreview: SetValue<boolean>;
    setStartClosing: SetValue<boolean>;
}) => {
    const track = useExperienceTracking();
    const [isHovering, setIsHovering] = useState(false);
    const [, setGlobalModal] = useGlobalModal();
    const [combinedBrandedVideoBackgroundImageUri] = useGlobalCombinedSpinachVideoBackgroundImageUri();
    const [spinachVideoBackgroundImageUri] = useGlobalSpinachVideoBackgroundImageUri();
    const [brandedImageUri] = useGlobalBrandedImageUri();

    const isLoading = Boolean(
        spinachVideoBackgroundImageUri && brandedImageUri && !combinedBrandedVideoBackgroundImageUri
    );

    const onClick = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Open Update Brand Image Modal',
        });
        setGlobalModal(GlobalModal.ImageUpload);
    };
    const onMouseLeave = () => {
        setIsHovering(false);
    };
    const onMouseEnter = () => {
        setIsHovering(true);
    };

    return (
        <Box
            style={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                backgroundColor: '#F4F6F6',
                padding: '5px',
            }}
        >
            {isLoading ? (
                <CircularProgress
                    size={'20px'}
                    style={{ marginRight: '5px', marginBottom: '-5px', color: lightTheme.primary.orangeDark }}
                />
            ) : combinedBrandedVideoBackgroundImageUri ? (
                <img
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Branded Image Preview',
                            HasBrandedImage: true,
                        });
                        setPreview(true);
                        window.onmousedown = () => {
                            setStartClosing(true);
                            setTimeout(() => {
                                setPreview(false);
                                setStartClosing(false);
                                window.onmousedown = null;
                            }, 800);
                        };
                    }}
                    style={{ width: '142px', height: '80px' }}
                    src={combinedBrandedVideoBackgroundImageUri}
                />
            ) : (
                <SpinachAIVideoImage
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Branded Image Preview',
                            HasBrandedImage: false,
                        });
                        setPreview(true);
                        window.onmousedown = () => {
                            setStartClosing(true);
                            setTimeout(() => {
                                setPreview(false);
                                setStartClosing(false);
                                window.onmousedown = null;
                            }, 800);
                        };
                    }}
                    style={{ width: '142px', height: '80px' }}
                />
            )}
            {isHovering ? (
                <PreviewBox onMouseEnter={(e) => e.preventDefault()}>
                    <BodyRegularOnboard style={{ color: lightTheme.primary.greenLight }}>Preview</BodyRegularOnboard>
                </PreviewBox>
            ) : (
                <></>
            )}
            <OutlinedButton disabled={isLoading} style={{ margin: '8px' }} onClick={onClick} title={'Update image'} />
        </Box>
    );
};

const AnimatedImage = styled.img<ImageProps>`
    width: 142px;
    height: 80px;
    transform: ${(props) => `scale(${props.scale})`};
`;

const AnimatedSvg = styled(SpinachAIVideoImage)<ImageProps>`
    width: 142px;
    height: 80px;
    ${(props) => (props.preview ? growAnimation(props) : shrinkAnimation(props))}
`;

export function VideoSettingsSection(): JSX.Element {
    const hasProFeatures = useProFeatures();
    const { width, height } = useWindowSize();
    const [preview, setPreview] = useState(false);
    const [startClosing, setStartClosing] = useState(false);
    const locked = !hasProFeatures;
    const navigateToUpgrade = useNavigateToUpgrade();
    const [combinedBrandedVideoBackgroundImageUri] = useGlobalCombinedSpinachVideoBackgroundImageUri();

    const isCustomBrandingEnabled = useIsCustomBrandingEnabled();

    if (!isCustomBrandingEnabled) {
        return <></>;
    }

    return (
        <>
            {preview ? (
                <ImagePreview startClosing={startClosing} width={width} height={height}>
                    {combinedBrandedVideoBackgroundImageUri ? (
                        <AnimatedImage preview={preview} scale={4} src={combinedBrandedVideoBackgroundImageUri} />
                    ) : (
                        <AnimatedSvg preview={preview} scale={4} />
                    )}
                </ImagePreview>
            ) : (
                <></>
            )}

            <Row>
                <HeaderThree>Video Settings</HeaderThree>
                <ProBadge />
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Customize your in-meeting note taker</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Column style={{}}>
                <Row vCenter>
                    <VideoCam style={{ fontSize: '25px', marginRight: '15px' }} />
                    {locked ? <UpgradeLockIcon analyticsPrefix="VideoSettings" /> : <></>}
                    <Label style={{ width: 'unset', opacity: locked ? 0.5 : 1 }}>Display settings</Label>
                </Row>
                <BodySmallOnboard>
                    {hasProFeatures ? (
                        <Row>
                            <span style={{ width: '40px' }} />
                            <Column>
                                <Row vCenter>
                                    <ImageSelector setStartClosing={setStartClosing} setPreview={setPreview} />
                                </Row>
                            </Column>
                        </Row>
                    ) : (
                        <Row>
                            <span style={{ width: '45px' }} />
                            <Column>
                                <BodyRegularOnboard>
                                    Custom in-meeting image and pause recording are disabled.
                                    <Anchor
                                        onClick={() => {
                                            navigateToUpgrade('Click Upgrade Video Settings');
                                        }}
                                    >
                                        Upgrade
                                    </Anchor>
                                    to access display settings
                                </BodyRegularOnboard>
                            </Column>
                        </Row>
                    )}
                </BodySmallOnboard>
            </Column>
            <Hairline />
            <Spacing factor={1 / 2} />
        </>
    );
}
