import React from 'react';
import { NavigateOptions, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ExperimentKey, WebUrlQuery } from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import { ClientPath } from '../components';
import { useLocationalSeriesId } from './useGlobalSearchParams';
import { useGlobalAuthedUser } from './useGlobalUser';

export type GlobalRouting = {
    navigateToRedirect: (redirectTo: string) => void;
    routeToScribeOnboarding: (navigateOptions?: NavigateOptions, searchOverride?: URLSearchParams) => void;
    routeToOnboarding: (navigateOptions?: NavigateOptions, searchOverride?: URLSearchParams) => void;
    routeToSeriesExperience: (seriesId: string, navigateOptions?: NavigateOptions) => void;
    routeToDashboard: (navigateOptions?: NavigateOptions, searchOverride?: URLSearchParams) => void;
    routeToDirectExperience: (navigateOptions?: NavigateOptions) => void;
    routeToDemoExperience: (navigateOptions?: NavigateOptions) => void;
    routeToSignUpExperience: (navigateOptions?: NavigateOptions) => void;
    routeToFirstSeriesFlow: (navigateOptions?: NavigateOptions) => void;
    routeToExperiment: (navigateOptions?: NavigateOptions) => void;
    routeToAIDashboard: (navigateOptions?: NavigateOptions, searchOverride?: URLSearchParams) => void;
    routeToVerify: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
    routeToVerifyGoogleCode: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
    routeToVerifyMicrosoftCode: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
    routeToEditSummary: (NavigateOptions?: NavigateOptions, searchOpts?: URLSearchParams) => void;
};

/**
 *
 * @returns returns thin wrappers around react router navigate calls
 */
export function useGlobalRouting(): GlobalRouting {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const seriesIdToRoute = useLocationalSeriesId();
    const [user] = useGlobalAuthedUser();

    let search = searchParams.toString();

    function routeToOnboarding(navigateOptions: NavigateOptions = {}, searchOverride?: URLSearchParams) {
        if (searchOverride) {
            search = searchOverride.toString();
        } else {
            search = searchParams.toString();
        }
        navigate(
            {
                pathname: ClientPath.Onboarding,
                search,
            },
            navigateOptions
        );
    }

    function routeToFirstSeriesFlow(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.CreateSeriesFlow, search }, navigateOptions);
    }

    const routeToSeriesExperience = React.useCallback(
        (
            seriesId: string,
            { forceNavigate, ...navigateOptions }: NavigateOptions & { forceNavigate?: boolean } = {}
        ) => {
            if (!isDemoSeries(seriesId)) {
                if (forceNavigate || !location.pathname.includes(ClientPath.Meeting)) {
                    navigate({ pathname: `${ClientPath.Meeting}/${seriesId}`, search }, navigateOptions);
                    return;
                }
            } else {
                navigate({ pathname: ClientPath.Demo, search }, navigateOptions);
            }
        },
        [location.pathname, navigate, search]
    );

    function routeToDashboard(navigateOptions: NavigateOptions = {}, searchOverride?: URLSearchParams) {
        const destination = ClientPath.Root;

        if (searchOverride) {
            search = searchOverride.toString();
        } else {
            search = searchParams.toString();
        }

        if (location.pathname !== destination) {
            navigate(
                { pathname: destination, search },
                {
                    ...navigateOptions,
                }
            );
        }
    }

    function routeToAIDashboard(navigateOptions: NavigateOptions = {}, searchOverride?: URLSearchParams) {
        if (searchOverride) {
            search = searchOverride.toString();
        } else {
            search = searchParams.toString();
        }

        if (user.isHidingAiDashboard) {
            routeToDashboard({ ...navigateOptions, replace: true }, searchOverride);
            return;
        }

        if (location.pathname !== ClientPath.AIHome) {
            navigate(
                { pathname: ClientPath.AIHome, search },
                {
                    ...navigateOptions,
                }
            );
        }
    }

    function routeToDirectExperience(navigateOptions: NavigateOptions = {}) {
        const onlySeriesId = user.getSeriesIdOfOnlySeries();
        const isLegacyUser =
            !user.metadata.experimentCodes ||
            (user.metadata.experimentCodes && !user.metadata.experimentCodes?.includes(ExperimentKey.StandupScribe));

        if (seriesIdToRoute) {
            /**
             * Clean up legacy or deeplink search params
             */
            if (searchParams.get(WebUrlQuery.Secret)) {
                searchParams.delete(WebUrlQuery.Secret);
                search = searchParams.toString();
            }

            if (searchParams.get(WebUrlQuery.Where)) {
                searchParams.delete(WebUrlQuery.Where);
                search = searchParams.toString();
            }

            if (searchParams.get(WebUrlQuery.Redirect)) {
                searchParams.delete(WebUrlQuery.Redirect);
                search = searchParams.toString();
            }

            navigate(
                { pathname: `${ClientPath.Meeting}/${seriesIdToRoute}`, search },
                {
                    ...navigateOptions,
                    replace: true,
                }
            );
        } else if (onlySeriesId && isLegacyUser) {
            navigate(
                { pathname: `${ClientPath.Meeting}/${onlySeriesId}`, search },
                {
                    ...navigateOptions,
                }
            );
        } else {
            routeToDashboard(navigateOptions);
        }
    }

    function routeToDemoExperience(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.Demo, search }, navigateOptions);
    }

    function routeToSignUpExperience(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.SignUp, search }, navigateOptions);
    }

    function routeToEditSummary(navigateOptions: NavigateOptions = {}) {
        navigate({ pathname: ClientPath.AIEditSummary, search }, navigateOptions);
    }

    /**
     *  Redirects user to verify page and replaces history by default.
     */
    function routeToVerify(navigateOptions: NavigateOptions = {}, searchOpts?: URLSearchParams) {
        if (searchOpts) {
            search = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(searchOpts),
            }).toString();
        } else {
            search = searchParams.toString();
        }

        navigate(
            {
                pathname: ClientPath.Verify,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToVerifyGoogleCode(navigateOptions: NavigateOptions = {}, searchOpts?: URLSearchParams) {
        if (searchOpts) {
            search = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(searchOpts),
            }).toString();
        } else {
            search = searchParams.toString();
        }

        navigate(
            {
                pathname: ClientPath.VerifyGoogleCode,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToVerifyMicrosoftCode(navigateOptions: NavigateOptions = {}, searchOpts?: URLSearchParams) {
        if (searchOpts) {
            search = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(searchOpts),
            }).toString();
        } else {
            search = searchParams.toString();
        }

        navigate(
            {
                pathname: ClientPath.VerifyMicrosoftCode,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToExperiment(navigateOptions: NavigateOptions = {}) {
        navigate(
            {
                pathname: ClientPath.Experiment,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function routeToScribeOnboarding(navigateOptions: NavigateOptions = {}, searchOverride?: URLSearchParams) {
        if (searchOverride) {
            search = searchOverride.toString();
        } else {
            search = searchParams.toString();
        }
        navigate(
            {
                pathname: ClientPath.OnboardingAI,
                search,
            },
            {
                replace: true,
                ...navigateOptions,
            }
        );
    }

    function navigateToRedirect(redirectTo: string) {
        if (redirectTo) {
            searchParams.delete(WebUrlQuery.Redirect);
            const search = searchParams.toString();
            navigate({ pathname: redirectTo, search }, { replace: true });
        }
    }

    return {
        navigateToRedirect,
        routeToDashboard,
        routeToOnboarding,
        routeToFirstSeriesFlow,
        routeToSeriesExperience,
        routeToDirectExperience,
        routeToDemoExperience,
        routeToSignUpExperience,
        routeToVerify,
        routeToVerifyGoogleCode,
        routeToVerifyMicrosoftCode,
        routeToExperiment,
        routeToScribeOnboarding,
        routeToAIDashboard,
        routeToEditSummary,
    };
}
