import { useEffect } from 'react';
import { v4 } from 'uuid';

import {
    VideoAgentSession,
    getNewAgentTopic,
    getNewVideoAgentSessionJSON,
    pseudoUniqueParticipantIdSuffix,
} from '@spinach-shared/models';
import { isLocalStage } from '@spinach-shared/utils';

import { getMockPreviousContext, useGlobalNullableVideoAgent } from '../../..';

export function useTestAgentSession({ botId, seriesId }: { botId: string; seriesId: string }) {
    const { session, setSession } = useGlobalNullableVideoAgent();
    useEffect(() => {
        async function exec() {
            const mockSession = getNewVideoAgentSessionJSON({
                botId,
                seriesId,
                hostId: v4(),
                pendingIntro: !!window.location.search?.includes('intro'),
                agenda: {
                    currentTopicId: undefined,
                    topics: [
                        // comment to keep line broken for easy disabling
                        getNewAgentTopic('Discussion Topic'),
                    ],
                },
                settings: {
                    isRoundtableEnabled: true,
                    isFeedbackCollectionEnabled: false,
                    isEnabledForChatCommandAudioOutput: true,
                    isVoicePrimaryCommandKind: true,
                },
                previousContext: getMockPreviousContext('coburn'),
                participants: [
                    { name: 'Coburn', pseudoUniqueId: `Coburn${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Kirill', pseudoUniqueId: `Kirill${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Matan', pseudoUniqueId: `Matan${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Josh', pseudoUniqueId: `Josh${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Lucas', pseudoUniqueId: `Lucas${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Oded', pseudoUniqueId: `Oded${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Yoav', pseudoUniqueId: `Yoav${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Derek', pseudoUniqueId: `Derek${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Karin', pseudoUniqueId: `Karin${pseudoUniqueParticipantIdSuffix}` },
                    { name: 'Kevin', pseudoUniqueId: `Kevin${pseudoUniqueParticipantIdSuffix}` },
                ],
                scheduledStartTime: new Date(Date.now() - 1000 * 60 * 5).toISOString(),
                scheduledEndTime: new Date(Date.now() + 1000 * 15).toISOString(),
            });

            const wrapper = new VideoAgentSession(mockSession);

            setSession(wrapper);
        }

        if (isLocalStage() && !session) {
            exec();
        }
    }, [botId, seriesId, session, setSession]);
}
