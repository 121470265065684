import { CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { getUser, patchAllSeriesSettings, patchUser } from '../../../../apis';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useProFeatures } from '../../../../hooks';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, BodySmallOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { Label } from './common';

const EditSummarySettingsSection = (): JSX.Element => {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const hasProFeatures = useProFeatures();
    const navigateToUpgrade = useNavigateToUpgrade();

    const [isLoading, setIsLoading] = useState(false);
    return hasProFeatures ? (
        <Column style={{}}>
            <Row vCenter>
                <EditIcon htmlColor={lightTheme.primary.greenLight} style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'unset' }}>Edit Summaries</Label>
            </Row>
            <BodySmallOnboard>
                <Row>
                    {isLoading ? (
                        <span
                            style={{
                                display: 'flex',
                                width: '40px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress
                                size={'16px'}
                                thickness={6}
                                style={{
                                    color: lightTheme.primary.greenLight,
                                }}
                            />
                        </span>
                    ) : (
                        <span style={{ width: '40px' }} />
                    )}
                    <Column>
                        <Row vCenter>
                            <SpinachSwitch
                                checked={
                                    user.metadata.isEditingAiEmailsByDefault ||
                                    (isLoading && !user.metadata.isEditingAiEmailsByDefault)
                                }
                                disabled={isLoading}
                                onChange={async () => {
                                    setIsLoading(true);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Toggle Enable Edit Summary (User Settings)',
                                        ToggleTo: !!user.metadata.isEditingAiEmailsByDefault ? 'Off' : 'On',
                                    });

                                    await Promise.all([
                                        patchAllSeriesSettings({
                                            isHostEditing: !user.metadata.isEditingAiEmailsByDefault,
                                        }),
                                        patchUser({
                                            metadata: {
                                                isEditingAiEmailsByDefault: !user.metadata.isEditingAiEmailsByDefault,
                                            },
                                        }),
                                    ]);

                                    const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                    if (updatedUser.user) {
                                        setUser(updatedUser.user);
                                    }

                                    setToastText('This has been applied to meetings that you have invited Spinach to.');

                                    setIsLoading(false);
                                }}
                            />
                            <BodyRegularOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                                Edit summaries before they publish
                            </BodyRegularOnboard>
                        </Row>
                    </Column>
                </Row>
            </BodySmallOnboard>
        </Column>
    ) : (
        <Row>
            <span style={{ width: '45px' }} />
            <Column>
                <BodyRegularOnboard>Summaries are immediately sent out.</BodyRegularOnboard>
                <BodyRegularOnboard>
                    {' '}
                    <Anchor
                        onClick={() => {
                            navigateToUpgrade('Click Upgrade Edit Summary (Settings Section)');
                        }}
                    >
                        Upgrade to Pro
                    </Anchor>{' '}
                    to edit beforehand.
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

const DisplayTranscriptSectionOption = () => {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const [isLoading, setIsLoading] = useState(false);
    return (
        <BodySmallOnboard>
            <Row>
                {isLoading ? (
                    <span
                        style={{
                            display: 'flex',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={'16px'}
                            thickness={6}
                            style={{
                                color: lightTheme.primary.greenLight,
                            }}
                        />
                    </span>
                ) : (
                    <span style={{ width: '40px' }} />
                )}
                <Column>
                    <Row vCenter>
                        <SpinachSwitch
                            checked={
                                !user.shouldHideMeetingTranscripts || (isLoading && user.shouldHideMeetingTranscripts)
                            }
                            disabled={isLoading}
                            onChange={async () => {
                                setIsLoading(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Toggle Enable Meeting Transcripts (User Settings)',
                                    ToggleTo: !!user.shouldHideMeetingTranscripts ? 'Off' : 'On',
                                });

                                await Promise.all([
                                    patchUser({
                                        metadata: {
                                            shouldHideMeetingTranscripts: !user.shouldHideMeetingTranscripts,
                                        },
                                    }),
                                ]);

                                const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                if (updatedUser.user) {
                                    setUser(updatedUser.user);
                                }

                                setToastText('This has been applied to meetings that you have invited Spinach to.');

                                setIsLoading(false);
                            }}
                        />
                        <BodyRegularOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                            Display transcripts for my meeting
                        </BodyRegularOnboard>
                    </Row>
                </Column>
            </Row>
        </BodySmallOnboard>
    );
};

const DisplayVideoPlaybackSectionOption = () => {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const [isLoading, setIsLoading] = useState(false);
    return (
        <BodySmallOnboard>
            <Row>
                {isLoading ? (
                    <span
                        style={{
                            display: 'flex',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={'16px'}
                            thickness={6}
                            style={{
                                color: lightTheme.primary.greenLight,
                            }}
                        />
                    </span>
                ) : (
                    <span style={{ width: '40px' }} />
                )}
                <Column>
                    <Row vCenter>
                        <SpinachSwitch
                            checked={!user.shouldHideMeetingVideos || (isLoading && user.shouldHideMeetingVideos)}
                            disabled={isLoading}
                            onChange={async () => {
                                setIsLoading(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Toggle Enable Meeting Video (User Settings)',
                                    ToggleTo: !!user.shouldHideMeetingVideos ? 'Off' : 'On',
                                });

                                await Promise.all([
                                    patchUser({
                                        metadata: {
                                            shouldHideMeetingVideos: !user.shouldHideMeetingVideos,
                                        },
                                    }),
                                ]);

                                const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                if (updatedUser.user) {
                                    setUser(updatedUser.user);
                                }

                                setToastText('This has been applied to meetings that you have invited Spinach to.');

                                setIsLoading(false);
                            }}
                        />
                        <BodyRegularOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                            Display video playback for my meetings
                        </BodyRegularOnboard>
                    </Row>
                </Column>
            </Row>
        </BodySmallOnboard>
    );
};

const MeetingRecordingSettingsSection = (): JSX.Element => {
    const [user] = useGlobalAuthedUser();
    const hasProFeatures = useProFeatures();
    const navigateToUpgrade = useNavigateToUpgrade();

    const shouldShowVideoPlaybackSectionOptions = user.isToggleMeetingVideosEnabled && !user.hideMeetingVideosOverride;
    const shouldShowTranscriptSectionOptions =
        user.isToggleMeetingTranscriptsEnabled && !user.hideMeetingTranscriptsOverride;

    if (!shouldShowVideoPlaybackSectionOptions && !shouldShowTranscriptSectionOptions) {
        return <></>;
    }

    return hasProFeatures ? (
        <Column style={{}}>
            <Row vCenter>
                <EditIcon htmlColor={lightTheme.primary.greenLight} style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'unset' }}>Meeting Settings</Label>
            </Row>
            {/* 
                hideMeetingVideosOverride and hideMeetingTranscriptsOverride are enterprise level toggles 
                that we'll control. If enabled we do not want to show the option for the user to update the settings
            */}
            {shouldShowVideoPlaybackSectionOptions ? <DisplayVideoPlaybackSectionOption /> : <></>}
            {shouldShowVideoPlaybackSectionOptions && shouldShowTranscriptSectionOptions ? (
                <Spacing factor={1 / 2} />
            ) : (
                <></>
            )}
            {shouldShowTranscriptSectionOptions ? <DisplayTranscriptSectionOption /> : <></>}
        </Column>
    ) : (
        <Row>
            <span style={{ width: '45px' }} />
            <Column>
                <BodyRegularOnboard>
                    Video playback and transcripts are enabled. Upgrade to access recording controls.
                </BodyRegularOnboard>
                <BodyRegularOnboard>
                    {' '}
                    <Anchor
                        onClick={() => {
                            navigateToUpgrade('Click Upgrade Meeting Seettings (Settings Section)');
                        }}
                    >
                        Upgrade to Pro
                    </Anchor>{' '}
                    to edit beforehand.
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

export function ModifySummarySection(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    return (
        <>
            <Row>
                <HeaderThree>Modify Summaries</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Make changes to the summary</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <EditSummarySettingsSection />
            {user.isToggleMeetingTranscriptsEnabled || user.isToggleMeetingVideosEnabled ? (
                <MeetingRecordingSettingsSection />
            ) : (
                <></>
            )}
        </>
    );
}
